import { render, staticRenderFns } from "./ApprovalCreate.vue?vue&type=template&id=3a640194&scoped=true&"
import script from "./ApprovalCreate.vue?vue&type=script&lang=ts&"
export * from "./ApprovalCreate.vue?vue&type=script&lang=ts&"
import style0 from "./ApprovalCreate.vue?vue&type=style&index=0&id=3a640194&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3a640194",
  null
  
)

export default component.exports