
import { Vue, Component, Mixins, Watch } from 'vue-property-decorator'; // 반드시 Vue를 vue-property-decorator에 있는 것을 써야함
import VueHoduCommon, { API_METHOD } from '@/mixin/VueHoduCommon';

import HoduLimitTextArea from '@/components/common/HoduLimitTextArea.vue'

import { namespace } from 'vuex-class';
const ModalInfo = namespace('ModalInfo');
const ApprovalInfo = namespace('ApprovalInfo');

import { CommonDatepickerModalInfo } from '@/store/modules/ModalInfo';

import { approval_enum, approval_interface, approval_modal_interface } from '@/model/approval';

import { ResizeObserver } from 'vue-resize';
import { t_event_file } from '@/model/event';

/**
 * Component 선언 및 extends Mixins(VueHoduCommon) << 공통 Vue
 */
@Component({
    components: {
        HoduLimitTextArea, ResizeObserver
    },
})
export default class ApprovalCreate extends Mixins(VueHoduCommon) {

    approval_enum : any = approval_enum;

    get computedApprovalTypeName() : string {

        if( this.approval == null ) return '';

        switch( this.approval.approval_type ) {
            case approval_enum.APPROVAL_TYPE.BASIC: return '사용자 결재문서';
            case approval_enum.APPROVAL_TYPE.FREE : return '기안서';
            case approval_enum.APPROVAL_TYPE.VACATION : return '휴가원';
            case approval_enum.APPROVAL_TYPE.BUSINESSTRIP : return '출장신청서';
            case approval_enum.APPROVAL_TYPE.HOLIDAY_WORK : return '휴일근무';
            case approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER : return '지출결의서';
            default: return '';
        }
    }

    /**
     * 기안서 작성자를 제외한 approver를 불러옴
     */
    get computedApproverExceptMe() : approval_interface.t_approval_approver[] {

        let approver : approval_interface.t_approval_approver[] = [];

        if( this.is_deputy == true ) {
            if( this.selected_deputy_employee != null ) {
                approver = this.approval.approver.filter(item => item.user_id != this.selected_deputy_employee.user_id);
            }
        }
        else {
            if( this.my_employee_info != null ) {
                approver = this.approval.approver.filter(item => item.user_id != this.my_employee_info.user_id);
            }
        }

        return approver;
    }

    /**
     * 선택된 휴가 타입
     */
    get computedSelectedVacationType() : any {

        if( this.approval == null || this.approval.approval_type != approval_enum.APPROVAL_TYPE.VACATION ||
            this.vacation_types == null || this.vacation_types.length < 1 ) {
            return null;
        }

        return this.vacation_types.filter(item => item.vacation_type_id == this.selected_vacation_type_id)[0];
    }

    /**
     * 선택된 출장 타입
     */
    get computedSelectedBusinessTripType() : any {

        if( this.approval == null || this.approval.approval_type != approval_enum.APPROVAL_TYPE.BUSINESSTRIP ) {
            return null;
        }

        if( this.selected_business_trip_type_id == '1' ) {
            return {
                business_trip_type_id : '1',
                business_trip_type_name : '종일',
                business_trip_type_daycount : '0',
            };
        }

        else if( this.selected_business_trip_type_id == '2' ) {
            return {
                business_trip_type_id : '2',
                business_trip_type_name : '오전',
                business_trip_type_daycount : '0.5',
                contents : { amPm : 'AM' }
            };
        }

        else if( this.selected_business_trip_type_id == '3' ) {
            return {
                business_trip_type_id : '3',
                business_trip_type_name : '오후',
                business_trip_type_daycount : '0.5',
                contents : { amPm : 'PM' }
            };
        }

        return null;
    }

    /**
     * 선택된 출장 신청서 타입
     */
    get computedSelectedHolidayWorkType() : any {

        if( this.approval == null || this.approval.approval_type != approval_enum.APPROVAL_TYPE.HOLIDAY_WORK ||
            this.holiday_work_types == null || this.holiday_work_types.length < 1 ) {
            return null;
        }

        return this.holiday_work_types.filter(item => item.holiday_work_type_id == this.selected_holiday_work_type_id)[0];
    }

    get computedTotalPrice() : string { 
        let price : number = 0;

        for( const transaction of this.transactions ) {
            if( transaction.price != null && String(transaction.price).length > 0 ) {
                const transaction_price = Number(transaction.price.replaceAll(",", ""));
                if( isNaN(transaction_price) ) {
                    continue;
                }
                price += transaction_price;
            }
        }

        return this.number_price_format(price);
    }

    get computedDeputyEmployeeData() {

        let deputy_emp_data : string[] = [];

        for( const employee of this.employees ) {

            if( employee.user_id == this.user_id ) {
                continue;
            }

            deputy_emp_data.push(this.createDeputyEmpData(employee));
        }

        return deputy_emp_data;
    }

    /**
     * ModalInfo.Action
     */
    @ModalInfo.Action doSetApproverSelectModalInfo ?: (params : approval_modal_interface.ApproverSelectModalInfo) => void;
    @ModalInfo.Action doSetApprovalTemplateExceptFileModalInfo ?: (params : approval_modal_interface.ApprovalTemplateExceptFileModalInfo) => void;
    @ModalInfo.Action doSetApprovalFileModalInfo ?: (params : approval_modal_interface.ApprovalFileModalInfo) => void;
    @ModalInfo.Action doSetCommonDatepickerModalInfo ?: (params : CommonDatepickerModalInfo) => void;
    @ModalInfo.Action doSetApprovalApproverFavoriteModalInfo ?: (params : approval_modal_interface.ApprovalApproverFavoriteModalInfo) => void;

    /**
     * @ApprovalInfo.State
     */
    @ApprovalInfo.State approval !: approval_interface.t_approval;

    /**
     * @ApprovalInfo.Action
     */
    @ApprovalInfo.Action doSetApproval ?: (params : approval_interface.t_approval) => void;

    departments : any[] = [];
    position : any[] = [];
    employees : any[] = [];
    vacation_types : any[] = [];
    holiday_work_types : any[] = [];
    templates : approval_interface.ApprovalTemplateBundle[] = [];
    default_template : any = null;
    vacation_template : any = null;
    business_trip_template : any = null;
    holiday_work_template : any = null;
    cash_disbursement_voucher_template : any = null;
    free_template : any = null;
    receive_template : any = null;

    my_employee_info : any = null;
    my_position_info : any = null;
    my_department_info : any = null;

    favorites : any[] = [];

    selected_vacation_type_id : string = "";  

    // vacation_start : Date = new Date();
    // vacation_end   : Date = new Date();

    // is_first_datepick : boolean = true;
    
    vacation_selected : Date[] = [];
    vacation_date_text : string = "";
    vacation_date_text_with_day : string = "";
    // vacation_start_text : string = "";
    // vacation_end_text   : string = "";

    selected_business_trip_type_id : string = "";
    
    business_trip_selected : Date[] = [];
    business_trip_date_text : string = "";
    business_trip_date_text_with_day : string = "";

    selected_holiday_work_type_id : string = "";

    holiday_work_selected : Date[] = [];
    holiday_work_date_text : string = "";
    holiday_work_date_text_with_day : string = "";

    approval_uid : string | null = null;

    added_templates : any[] = [];
    is_self_approve : boolean = false;
    self_approve_message : string = "";

    all_files : any[] = [];

    selected_approver_favorite_id : string = "";

    transactions : approval_interface.t_approval_contents_transaction[] = [{
        "subject" : "",
        "date" : new Date(),
        "price" : "",
        "account" : "",
        "remark" : ""
    }];

    is_deputy : boolean = false;
    input_deputy_emp_data : string = "";
    selected_deputy_employee : any = null;

    beforeMount() {
        this.approval_uid = this.$route.params.approval_id;
    }

    async mounted() : Promise<void> {
        await this.getTotalOrganizationData();
        
        if( this.approval_uid != null ) {
            await this.getSelectedApprovalInfo();

            // 기안서에 파일이 존재하는데 결재자가 존재하지 않는다면 자가 결재 가능인지 검사를 한다 
            if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC && this.approval.approver.length <= 1 && this.approval.contents.files != null && this.approval.contents.files.length > 0 ) {

                for( const apporval_template of this.approval.contents.files ) {

                    console.log(apporval_template);

                    for( const template of this.templates ) {

                        let is_find_template = false;

                        for( const file of template.files ) {

                            // template_id 와 template_class_id가 반대로 저장되는 문제가 있어서 반대로 비교해야한다 (고치려면 마이그레이션 + 업데이트가 필요한 상황)
                            if( apporval_template.template_class_id == file.template_id && apporval_template.template_id == file.template_class_id ) {
                                file['template_class_name'] = template.template_class_name;
                                this.added_templates.push(file);
                                is_find_template = true;
                                break;
                            }
                        }

                        if( is_find_template == true ) break;
                    }
                }

                for( const template of this.added_templates ) {

                    console.log(template);
                    
                    let is_self_approve : boolean = false;
                    if( template.self_approver != null && template.self_approver.length > 0 ) {
                        const target = template.self_approver.filter(approver => approver.user_id == this.user_id);
                        is_self_approve = target.length > 0;

                        console.log(target);

                        if( is_self_approve == false ) {
                            if( this.self_approve_message.length > 0 ) this.self_approve_message += "\n";
                            this.self_approve_message += `${template.template_name} (${template.template_class_name})`;
                        }
                    }
                    else {
                        if( this.self_approve_message.length > 0 ) this.self_approve_message += "\n";
                        this.self_approve_message += `${template.template_name} (${template.template_class_name})`;
                    }

                    if( is_self_approve == true ) {
                        this.is_self_approve = true;
                    }
                    
                }
            }
            
            if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.VACATION ) {
                this.selected_vacation_type_id = this.approval.contents.vacation_type_id ? String(this.approval.contents.vacation_type_id) : '';
                
                if( this.approval.contents && this.approval.contents.vacation_selected ) {
                    for( const selected_date of this.approval.contents.vacation_selected ) {
                        this.vacation_selected.push(new Date([selected_date.substring(0,4), selected_date.substring(4,6), selected_date.substring(6,8)].join('-')));
                    }
                }
                else {
                    // 초기값 없도록 변경
                    // this.vacation_selected.push(new Date());
                }
                this.makeVacationText();
            }
            else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BUSINESSTRIP ) {
                this.selected_business_trip_type_id = this.approval.contents.vacation_type_id ? String(this.approval.contents.vacation_type_id) : '';
                
                if( this.approval.contents && this.approval.contents.vacation_selected ) {
                    for( const selected_date of this.approval.contents.vacation_selected ) {
                        this.business_trip_selected.push(new Date([selected_date.substring(0,4), selected_date.substring(4,6), selected_date.substring(6,8)].join('-')));
                    }
                }
                else {
                    // 초기값 없도록 변경
                    // this.business_trip_selected.push(new Date());
                }
                this.makeBusinessTripText();
            }
            if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.HOLIDAY_WORK ) {
                this.selected_holiday_work_type_id = this.approval.contents.vacation_type_id ? String(this.approval.contents.vacation_type_id) : '';
                
                if( this.approval.contents && this.approval.contents.vacation_selected ) {
                    for( const selected_date of this.approval.contents.vacation_selected ) {
                        this.holiday_work_selected.push(new Date([selected_date.substring(0,4), selected_date.substring(4,6), selected_date.substring(6,8)].join('-')));
                    }
                }
                else {
                    // 초기값 없도록 변경
                    // this.holiday_work_selected.push(new Date());
                }
                this.makeHolidayWorkText();
            }
            else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER ) {
                
                // 저장된 지출내역 그대로 가져와서 덮어 씌움
                if( this.approval.contents && this.approval.contents.transactions ) {
                    this.transactions = JSON.parse(JSON.stringify(this.approval.contents.transactions));
                }

            }
            
        }

        this.$nextTick(() => this.setScroll());

        $(document).ready(function() {
            $('.hodu_limit_text_area').on( 'keyup', function (e){
                $(this).css('height', 'auto');
                $(this).height(this.scrollHeight);
            });
            $('.hodu_limit_text_area').keyup();
        });
    }

    /**
     * 스크롤 설정
     */
    setScroll() : void {

        const title_height = $('.title_box').outerHeight();

        // @ts-ignore
        $('#approval_create_scroll').mCustomScrollbar({
            axis : 'y',
            scrollbarPosition : 'outside',
            mouseWheelPixels : 100,
            scrollInertia : 60,
            autoDraggerLength : false,
            setHeight : window.innerHeight - (title_height ? title_height : 0),
        });
    }

    /**
     * 종합적인 조직도 데이터 조회
     */
    async getTotalOrganizationData() : Promise<void> {
        try {
            await this.hodu_show_indicator();
            await Promise.all([this.getOrganizationPosition(), this.getOrganization(), this.getOrganizationEmp(), this.getOrganizationVacationTypeInfo(), this.getOrganizationHolidayWorkTypeInfo(), this.getApprovalApproverFavorites()]);
            await this.hodu_hide_indicator();

            // 본인 정보 찾기 (my_employee_info, my_position_info, my_department_info)
            if( this.my_employee_info == null ) {
                this.hodu_show_dialog("cancel", "직원 정보가 존재하지 않습니다\n관리자에게 문의하세요", ['확인'], [() => { this.movePrevPage(); }]);
                return;
            }

            // TODO my_employee_info.use_annual_count 재설정 해줘야함
            // 연차 데이터 목록에서 현재 적용되야할 연차 데이터가 있다면 해당 데이터로 교체함
            if( this.my_employee_info.past_emp_info != null && this.my_employee_info.past_emp_info.length > 0 ) {
                let current = new Date();
                let current_date_number = Number(`${current.getFullYear()}${`0${current.getMonth() + 1}`.slice(-2)}${`0${current.getDate()}`.slice(-2)}`);

                for( const emp of this.my_employee_info.past_emp_info ) {
                    if( Number(emp.annual_start) <= current_date_number && current_date_number <= Number(emp.annual_end) ) {
                        this.my_employee_info.use_annual_count = emp.use_annual_count;
                        break;
                    }
                }
            }

            for( const department of this.departments ) {
                if( this.my_employee_info.dept_id == department.dept_id ) {
                    this.my_department_info = department;
                    this.approval.dept_id = department.dept_id;
                    break;
                }
            }

            await this.hodu_show_indicator();
            await this.getApprovalTemplateInfo();
            await this.hodu_hide_indicator();

            if( this.my_department_info == null ) {
                this.hodu_show_dialog("cancel", "부서가 설정되어 있지 않습니다\n관리자에게 문의하세요", ['확인'], [() => { this.movePrevPage(); }]);
                return;
            }

            for( const pos of this.position ) {
                if( this.my_employee_info.pos_id == pos.pos_id ) {
                    this.my_position_info = pos;
                    this.approval.pos_id = pos.pos_id;
                    break;
                }
            }

            if( this.my_position_info == null ) {
                this.hodu_show_dialog("cancel", "직급이 설정되어 있지 않습니다\n관리자에게 문의하세요", ['확인'], [() => { this.movePrevPage(); }]);
                return;
            }

            // 신규 생성인 경우
            if( this.approval_uid == null ) {
                this.setDefaultApprover();
            }

            // 복사라면 요청일 현재날짜로 리셋
            if( this.approval.is_copy == true ) {
                this.approval.audit_created = new Date();
            }

            // 복사라면 파일 복사
            if( this.approval.is_copy == true && this.approval.contents.files != null && this.approval.contents.files.length > 0 ) {

                const vue = this;
                const file_count : number = this.approval.contents.files.length;

                for( let i = 0; i < file_count; i++ ) {
                    const file = this.approval.contents.files[i];

                    await this.$http({
                        url : `/app_images/${file.url}`,
                        method : 'GET',
                        responseType: 'blob'
                    }).then(async(response) => {
                        console.log(response);
                        const blob : Blob = new Blob([response.data]);
                        const copy_file : File = new File([blob], file.name, { type: blob.type, lastModified : new Date().getTime()});

                        // 파일 처리
                        vue.all_files.push({
                            index : ( vue.all_files.length ), 
                            file : {
                                name: copy_file.name,
                                mimeType: copy_file.type,
                                url: "",
                                size: copy_file.size,
                                date: new Date()
                            },
                            js_file : copy_file
                        });

                    }).catch(async(e) => {
                        this.hodu_error_process(e, true, false);
                    });
            
                }
            }

            // 복사 + 지출결의서라면 지출내역 지출일자 리셋 및 지출내역 추가
            if( this.approval.is_copy == true && this.approval.approval_type == approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER && this.approval.contents.transactions != null ) {
                
                // 지출일자 현재 시간으로 리셋 && 금액 소수점
                for( const transaction of this.approval.contents.transactions ) {
                    transaction.date = new Date();
                    transaction.price = this.number_price_format(transaction.price);
                }
                
                this.transactions.splice(0, this.transactions.length);
                this.transactions = this.transactions.concat(this.approval.contents.transactions);
            }

        } catch(e) {
            this.hodu_show_dialog("cancel", "조직도 조회 중 오류 발생", ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        } finally {
            await this.hodu_hide_indicator();
        }
    }

    /**
     * 조직도 조회
     */
    async getOrganization() : Promise<void> {

        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.dept_info ) {
                throw new Error("조직도 조회 중 오류 발생");
            }

            this.departments.splice(0, this.departments.length);
            this.departments = this.departments.concat(response.data.data.dept_info);

        } catch(e) {
            throw e;
        }

    }

    /**
     * 조직도 직급 조회
     */
    async getOrganizationPosition() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/position`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.pos_info ) {
                throw new Error("조직도 직급 조회 중 오류 발생");
            }

            response.data.data.pos_info.sort((o1, o2) : number => {
                if( o1.pos_seq > o2.pos_seq ) return 1;
                else if( o1.pos_seq < o2.pos_seq ) return -1;
                return 0;
            })

            this.position.splice(0, this.position.length);
            this.position = this.position.concat(response.data.data.pos_info);

        } catch(e) {
            throw e;
        }
    }

    /**
     * 조직도 전체 직원 조회
     */
    async getOrganizationEmp() : Promise<void> {
        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/emp`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.emp_info ) {
                throw new Error("조직도 전체 직원 조회 중 오류 발생");
            }

            this.employees.splice(0, this.employees.length);
            this.employees = this.employees.concat(response.data.data.emp_info);

            for( const employee of this.employees ) {
                if( employee.user_id == this.user_id ) {
                    this.my_employee_info = employee;
                    break;
                }
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 휴가타입 조회
     */
    async getOrganizationVacationTypeInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/vacationtype`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.vacation_type_info ) {
                throw new Error("휴가타입 조회 중 오류 발생")
            }

            this.vacation_types.splice(0, this.vacation_types.length);
            this.vacation_types = this.vacation_types.concat(response.data.data.vacation_type_info);
            this.vacation_types.sort((o1, o2) : number => {
                if( o1.vacation_type_id > o2.vacation_type_id ) return 1;
                else return -1;
            });

        } catch(e) {
            throw e
        }
    }

    /**
     * 휴일근무 타입 조회
     */
    async getOrganizationHolidayWorkTypeInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/organization/holidayworktype`, API_METHOD.GET, null, false);

            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.holiday_work_type_info ) {
                throw new Error("휴가타입 조회 중 오류 발생")
            }

            this.holiday_work_types.splice(0, this.holiday_work_types.length);
            this.holiday_work_types = this.holiday_work_types.concat(response.data.data.holiday_work_type_info);
            this.holiday_work_types.sort((o1, o2) : number => {
                if( o1.holiday_work_type_id > o2.holiday_work_type_id ) return 1;
                else return -1;
            });

        } catch(e) {
            throw e
        }
    }

    async getApprovalApproverFavorites() {

        try {

            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite`, API_METHOD.GET);

            console.log(response);

            if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.favorites ) {
                throw new Error("결재자 즐겨찾기 조회 오류");
            }

            this.favorites.splice(0, this.favorites.length);
            this.favorites = this.favorites.concat(response.data.data.favorites);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
            this.hodu_show_dialog("cancel", "결재자 즐겨찾기 조회 실패", ['확인']);
        }

    }

    /**
     * 양식 설정 조회
     */
    async getApprovalTemplateInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/form/class?include_templates=${true}`, API_METHOD.GET, null, false);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                throw new Error("전자결재 양식 정보 조회 중 오류 발생");
            }

            const approval_templates = response.data.data.approval_templates;

            this.templates.splice(0, this.templates.length);
            this.templates = this.templates.concat(approval_templates.filter(template_class => template_class.template_state == '0' || template_class.template_state == '10'));

            const default_template_class = approval_templates.filter(template_class => template_class.template_state == '20');
            const vacation_template_class = approval_templates.filter(template_class => template_class.template_state == '30');
            const business_trip_template_class = approval_templates.filter(template_class => template_class.template_state == '40');
            const holiday_work_template_class = approval_templates.filter(template_class => template_class.template_state == '80');
            const cash_disbursement_voucher_template_class = approval_templates.filter(template_class => template_class.template_state == '60');
            const free_template_class = approval_templates.filter(template_class => template_class.template_state == '70');
            const receive_template_class = approval_templates.filter(template_class => template_class.template_state == '50');

            // console.log(approval_templates);

            // 전체 기본결재자
            if( default_template_class.length > 0 ) {
                const target = JSON.parse(JSON.stringify(default_template_class[0]));
                const taregt_approver = target.files[0].approver;

                // 가공
                let replace_approver : approval_interface.t_approval_approver[] = [];
                for( const approver of taregt_approver ) {
                    approver.comment = '';
                    approver.seq = this.approval.approver.length,
                    approver.state = this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                    approver.rev = 0;

                    if( approver.approver_type == 'EMPLOYEE' ) {
                        replace_approver.push(approver);
                        continue;
                    }

                    const dept_id_length = this.my_department_info.dept_id_array.length;
                    for( let i = (dept_id_length - 1); i >= 0; i-- ) {
                        const dept_id = this.my_department_info.dept_id_array[i];
                        const targets = this.employees.filter(employee => employee.dept_id == dept_id && employee.pos_id == approver.pos_id && employee.is_approver == true);

                        if( targets.length < 1 ) continue;

                        const target_employee = targets[0];

                        const employee_obj : approval_interface.t_approval_approver = {
                            "rev" : 0,
                            "seq" : this.approval.approver.length,
                            "state" : this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                            "comment" : "",
                            "user_id" : target_employee.user_id,
                            "pos_name" : this.getPositionName(target_employee.pos_id),
                            "dept_name" : this.getDepartmentName(target_employee.dept_id),
                            "user_name" : target_employee.user_name,
                            "pos_id" : target_employee.pos_id,
                            "dept_id" : target_employee.dept_id,
                            "user_pic" : target_employee.user_pic
                        };

                        replace_approver.push(employee_obj);

                        if( approver.include_parent == false ) break;
                    }
                }

                // 본인이 포함되어있다면 본인 이하(본인 포함)의 결재자를 전부 없앰
                const include_me_filter_approver_list = replace_approver.filter(item => item.user_id == this.user_id);
                if( include_me_filter_approver_list.length > 0 ) {
                    const target = include_me_filter_approver_list[0];
                    const index = replace_approver.indexOf(target);
                    
                    // 본인만 제외하도록 임시 변경
                    replace_approver = replace_approver.filter(item => item.user_id != this.user_id);
                    // replace_approver = replace_approver.slice(index + 1, replace_approver.length);

                    for( const approver of replace_approver ) {
                        approver.seq = replace_approver.indexOf(approver) + 1;
                        approver.state = (replace_approver.indexOf(approver) + 1 == 1) ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT;
                    } 
                }

                target.approver = replace_approver;
                this.default_template = target;
            }

            // 휴가원 기본결재자
            if( vacation_template_class.length > 0 ) {

                console.log(vacation_template_class);

                const target = JSON.parse(JSON.stringify(vacation_template_class[0]));
                const taregt_approver = target.files[0].approver;
                const target_self_approver = target.files[0].self_approver;

                // 가공
                let replace_approver : approval_interface.t_approval_approver[] = [];
                for( const approver of taregt_approver ) {
                    approver.comment = '';
                    approver.seq = this.approval.approver.length,
                    approver.state = this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                    approver.rev  = 0;

                    if( approver.approver_type == 'EMPLOYEE' ) {
                        replace_approver.push(approver);
                        continue;
                    }

                    const dept_id_length = this.my_department_info.dept_id_array.length;
                    for( let i = (dept_id_length - 1); i >= 0; i-- ) {
                        const dept_id = this.my_department_info.dept_id_array[i];
                        const targets = this.employees.filter(employee => employee.dept_id == dept_id && employee.pos_id == approver.pos_id && employee.is_approver == true);

                        if( targets.length < 1 ) continue;

                        const target_employee = targets[0];

                        const employee_obj : approval_interface.t_approval_approver = {
                            "rev" : 0,
                            "seq" : this.approval.approver.length,
                            "state" : this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                            "comment" : "",
                            "user_id" : target_employee.user_id,
                            "pos_name" : this.getPositionName(target_employee.pos_id),
                            "dept_name" : this.getDepartmentName(target_employee.dept_id),
                            "user_name" : target_employee.user_name,
                            "pos_id" : target_employee.pos_id,
                            "dept_id" : target_employee.dept_id,
                            "user_pic" : target_employee.user_pic
                        };

                        replace_approver.push(employee_obj);

                        if( approver.include_parent == false ) break;
                    }
                }

                // 본인이 포함되어있다면 본인 이하(본인 포함)의 결재자를 전부 없앰
                const include_me_filter_approver_list = replace_approver.filter(item => item.user_id == this.user_id);
                if( include_me_filter_approver_list.length > 0 ) {
                    const target = include_me_filter_approver_list[0];
                    const index = replace_approver.indexOf(target);

                    // 본인만 제외하도록 임시 변경
                    replace_approver = replace_approver.filter(item => item.user_id != this.user_id);
                    // replace_approver = replace_approver.slice(index + 1, replace_approver.length);

                    for( const approver of replace_approver ) {
                        approver.seq = replace_approver.indexOf(approver) + 1;
                        approver.state = (replace_approver.indexOf(approver) + 1 == 1) ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT;
                    } 
                }

                target.approver = replace_approver;
                target.self_approver = target_self_approver;
                this.vacation_template = target;
                
                // 휴가원일때만 체크
                if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.VACATION ) {
                    const self_approver = this.vacation_template.self_approver.filter(approver => approver.user_id == this.user_id);
                    if( self_approver.length > 0 ) this.is_self_approve = true;
                }
            }

            // 출장신청서 기본결재자
            if( business_trip_template_class.length > 0 ) {
                const target = JSON.parse(JSON.stringify(business_trip_template_class[0]));
                const taregt_approver = target.files[0].approver;
                const target_self_approver = target.files[0].self_approver;

                // 가공
                let replace_approver : approval_interface.t_approval_approver[] = [];
                for( const approver of taregt_approver ) {
                    approver.comment = '';
                    approver.seq = this.approval.approver.length,
                    approver.state = this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                    approver.rev  = 0;

                    if( approver.approver_type == 'EMPLOYEE' ) {
                        replace_approver.push(approver);
                        continue;
                    }

                    const dept_id_length = this.my_department_info.dept_id_array.length;
                    for( let i = (dept_id_length - 1); i >= 0; i-- ) {
                        const dept_id = this.my_department_info.dept_id_array[i];
                        const targets = this.employees.filter(employee => employee.dept_id == dept_id && employee.pos_id == approver.pos_id && employee.is_approver == true);

                        if( targets.length < 1 ) continue;

                        const target_employee = targets[0];

                        const employee_obj : approval_interface.t_approval_approver = {
                            "rev" : 0,
                            "seq" : this.approval.approver.length,
                            "state" : this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                            "comment" : "",
                            "user_id" : target_employee.user_id,
                            "pos_name" : this.getPositionName(target_employee.pos_id),
                            "dept_name" : this.getDepartmentName(target_employee.dept_id),
                            "user_name" : target_employee.user_name,
                            "pos_id" : target_employee.pos_id,
                            "dept_id" : target_employee.dept_id,
                            "user_pic" : target_employee.user_pic,
                        };

                        replace_approver.push(employee_obj);

                        if( approver.include_parent == false ) break;
                    }
                }

                // 본인이 포함되어있다면 본인 이하(본인 포함)의 결재자를 전부 없앰
                const include_me_filter_approver_list = replace_approver.filter(item => item.user_id == this.user_id);
                if( include_me_filter_approver_list.length > 0 ) {
                    const target = include_me_filter_approver_list[0];
                    const index = replace_approver.indexOf(target);

                    // 본인만 제외하도록 임시 변경
                    replace_approver = replace_approver.filter(item => item.user_id != this.user_id);
                    // replace_approver = replace_approver.slice(index + 1, replace_approver.length);

                    for( const approver of replace_approver ) {
                        approver.seq = replace_approver.indexOf(approver) + 1;
                        approver.state = (replace_approver.indexOf(approver) + 1 == 1) ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT;
                    } 
                }

                target.approver = replace_approver;
                target.self_approver = target_self_approver;
                this.business_trip_template = target;

                // 출장신청서일때만 체크
                if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BUSINESSTRIP ) {
                    const self_approver = this.business_trip_template.self_approver.filter(approver => approver.user_id == this.user_id);
                    if( self_approver.length > 0 ) this.is_self_approve = true;
                }
            }

            // 휴일근무 기본결재자
            if( holiday_work_template_class.length > 0 ) {

                console.log(holiday_work_template_class);

                const target = JSON.parse(JSON.stringify(holiday_work_template_class[0]));
                const taregt_approver = target.files[0].approver;
                const target_self_approver = target.files[0].self_approver;

                // 가공
                let replace_approver : approval_interface.t_approval_approver[] = [];
                for( const approver of taregt_approver ) {
                    approver.comment = '';
                    approver.seq = this.approval.approver.length,
                    approver.state = this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                    approver.rev  = 0;

                    if( approver.approver_type == 'EMPLOYEE' ) {
                        replace_approver.push(approver);
                        continue;
                    }

                    const dept_id_length = this.my_department_info.dept_id_array.length;
                    for( let i = (dept_id_length - 1); i >= 0; i-- ) {
                        const dept_id = this.my_department_info.dept_id_array[i];
                        const targets = this.employees.filter(employee => employee.dept_id == dept_id && employee.pos_id == approver.pos_id && employee.is_approver == true);

                        if( targets.length < 1 ) continue;

                        const target_employee = targets[0];

                        const employee_obj : approval_interface.t_approval_approver = {
                            "rev" : 0,
                            "seq" : this.approval.approver.length,
                            "state" : this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                            "comment" : "",
                            "user_id" : target_employee.user_id,
                            "pos_name" : this.getPositionName(target_employee.pos_id),
                            "dept_name" : this.getDepartmentName(target_employee.dept_id),
                            "user_name" : target_employee.user_name,
                            "pos_id" : target_employee.pos_id,
                            "dept_id" : target_employee.dept_id,
                            "user_pic" : target_employee.user_pic
                        };

                        replace_approver.push(employee_obj);

                        if( approver.include_parent == false ) break;
                    }
                }

                // 본인이 포함되어있다면 본인 이하(본인 포함)의 결재자를 전부 없앰
                const include_me_filter_approver_list = replace_approver.filter(item => item.user_id == this.user_id);
                if( include_me_filter_approver_list.length > 0 ) {
                    const target = include_me_filter_approver_list[0];
                    const index = replace_approver.indexOf(target);

                    // 본인만 제외하도록 임시 변경
                    replace_approver = replace_approver.filter(item => item.user_id != this.user_id);
                    // replace_approver = replace_approver.slice(index + 1, replace_approver.length);

                    for( const approver of replace_approver ) {
                        approver.seq = replace_approver.indexOf(approver) + 1;
                        approver.state = (replace_approver.indexOf(approver) + 1 == 1) ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT;
                    } 
                }

                target.approver = replace_approver;
                target.self_approver = target_self_approver;
                this.holiday_work_template = target;
                
                // 휴일근무일때만 체크
                if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.HOLIDAY_WORK ) {
                    const self_approver = this.holiday_work_template.self_approver.filter(approver => approver.user_id == this.user_id);
                    if( self_approver.length > 0 ) this.is_self_approve = true;
                }

            }

            // 지출결의서 기본결재자
            if( cash_disbursement_voucher_template_class.length > 0 ) {
                const target = JSON.parse(JSON.stringify(cash_disbursement_voucher_template_class[0]));
                const taregt_approver = target.files[0].approver;
                const target_self_approver = target.files[0].self_approver;

                // 가공
                let replace_approver : approval_interface.t_approval_approver[] = [];
                for( const approver of taregt_approver ) {
                    approver.comment = '';
                    approver.seq = this.approval.approver.length,
                    approver.state = this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                    approver.rev  = 0;

                    if( approver.approver_type == 'EMPLOYEE' ) {
                        replace_approver.push(approver);
                        continue;
                    }

                    const dept_id_length = this.my_department_info.dept_id_array.length;
                    for( let i = (dept_id_length - 1); i >= 0; i-- ) {
                        const dept_id = this.my_department_info.dept_id_array[i];
                        const targets = this.employees.filter(employee => employee.dept_id == dept_id && employee.pos_id == approver.pos_id && employee.is_approver == true);

                        if( targets.length < 1 ) continue;

                        const target_employee = targets[0];

                        const employee_obj : approval_interface.t_approval_approver = {
                            "rev" : 0,
                            "seq" : this.approval.approver.length,
                            "state" : this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                            "comment" : "",
                            "user_id" : target_employee.user_id,
                            "pos_name" : this.getPositionName(target_employee.pos_id),
                            "dept_name" : this.getDepartmentName(target_employee.dept_id),
                            "user_name" : target_employee.user_name,
                            "pos_id" : target_employee.pos_id,
                            "dept_id" : target_employee.dept_id,
                            "user_pic" : target_employee.user_pic
                        };

                        replace_approver.push(employee_obj);

                        if( approver.include_parent == false ) break;
                    }
                }

                // 본인이 포함되어있다면 본인 이하(본인 포함)의 결재자를 전부 없앰
                const include_me_filter_approver_list = replace_approver.filter(item => item.user_id == this.user_id);
                if( include_me_filter_approver_list.length > 0 ) {
                    const target = include_me_filter_approver_list[0];
                    const index = replace_approver.indexOf(target);

                    // 본인만 제외하도록 임시 변경
                    replace_approver = replace_approver.filter(item => item.user_id != this.user_id);
                    // replace_approver = replace_approver.slice(index + 1, replace_approver.length);

                    for( const approver of replace_approver ) {
                        approver.seq = replace_approver.indexOf(approver) + 1;
                        approver.state = (replace_approver.indexOf(approver) + 1 == 1) ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT;
                    } 
                }

                target.approver = replace_approver;
                target.self_approver = target_self_approver;
                this.cash_disbursement_voucher_template = target;

                // 지출결의서일때만 체크
                if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER ) {
                    const self_approver = this.cash_disbursement_voucher_template.self_approver.filter(approver => approver.user_id == this.user_id);
                    if( self_approver.length > 0 ) this.is_self_approve = true;
                }
            }

            if( free_template_class.length > 0 ) {
                const target = JSON.parse(JSON.stringify(free_template_class[0]));
                const taregt_approver = target.files[0].approver;
                const target_self_approver = target.files[0].self_approver;

                // 가공
                let replace_approver : approval_interface.t_approval_approver[] = [];
                for( const approver of taregt_approver ) {
                    approver.comment = '';
                    approver.seq = this.approval.approver.length,
                    approver.state = this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                    approver.rev  = 0;

                    if( approver.approver_type == 'EMPLOYEE' ) {
                        replace_approver.push(approver);
                        continue;
                    }

                    const dept_id_length = this.my_department_info.dept_id_array.length;
                    for( let i = (dept_id_length - 1); i >= 0; i-- ) {
                        const dept_id = this.my_department_info.dept_id_array[i];
                        const targets = this.employees.filter(employee => employee.dept_id == dept_id && employee.pos_id == approver.pos_id && employee.is_approver == true);

                        if( targets.length < 1 ) continue;

                        const target_employee = targets[0];

                        const employee_obj : approval_interface.t_approval_approver = {
                            "rev" : 0,
                            "seq" : this.approval.approver.length,
                            "state" : this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                            "comment" : "",
                            "user_id" : target_employee.user_id,
                            "pos_name" : this.getPositionName(target_employee.pos_id),
                            "dept_name" : this.getDepartmentName(target_employee.dept_id),
                            "user_name" : target_employee.user_name,
                            "pos_id" : target_employee.pos_id,
                            "dept_id" : target_employee.dept_id,
                            "user_pic" : target_employee.user_pic
                        };

                        replace_approver.push(employee_obj);

                        if( approver.include_parent == false ) break;
                    }
                }

                // 본인이 포함되어있다면 본인 이하(본인 포함)의 결재자를 전부 없앰
                const include_me_filter_approver_list = replace_approver.filter(item => item.user_id == this.user_id);
                if( include_me_filter_approver_list.length > 0 ) {
                    const target = include_me_filter_approver_list[0];
                    const index = replace_approver.indexOf(target);

                    // 본인만 제외하도록 임시 변경
                    replace_approver = replace_approver.filter(item => item.user_id != this.user_id);
                    // replace_approver = replace_approver.slice(index + 1, replace_approver.length);

                    for( const approver of replace_approver ) {
                        approver.seq = replace_approver.indexOf(approver) + 1;
                        approver.state = (replace_approver.indexOf(approver) + 1 == 1) ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT;
                    } 
                }

                target.approver = replace_approver;
                target.self_approver = target_self_approver;
                this.free_template = target;

                // 무양식 기안서일때만 체크
                if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.FREE ) {
                    const self_approver = this.free_template.self_approver.filter(approver => approver.user_id == this.user_id);
                    if( self_approver.length > 0 ) this.is_self_approve = true;
                }
            }

            // 기본 수신참조
            if( receive_template_class.length > 0 ) {
                const target = JSON.parse(JSON.stringify(receive_template_class[0]));
                const taregt_approver = target.files[0].approver;

                // 가공
                let replace_approver : approval_interface.t_approval_approver[] = [];
                for( const approver of taregt_approver ) {
                    approver.comment = '';
                    approver.seq = this.approval.approver.length,
                    approver.state = this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                    approver.rev  = 0;

                    if( approver.approver_type == 'EMPLOYEE' ) {
                        replace_approver.push(approver);
                        continue;
                    }

                    const dept_id_length = this.my_department_info.dept_id_array.length;
                    for( let i = (dept_id_length - 1); i >= 0; i-- ) {
                        const dept_id = this.my_department_info.dept_id_array[i];
                        const targets = this.employees.filter(employee => employee.dept_id == dept_id && employee.pos_id == approver.pos_id && employee.is_approver == true);

                        if( targets.length < 1 ) continue;

                        const target_employee = targets[0];

                        const employee_obj : approval_interface.t_approval_approver = {
                            "rev" : 0,
                            "seq" : this.approval.approver.length,
                            "state" : this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                            "comment" : "",
                            "user_id" : target_employee.user_id,
                            "pos_name" : this.getPositionName(target_employee.pos_id),
                            "dept_name" : this.getDepartmentName(target_employee.dept_id),
                            "user_name" : target_employee.user_name,
                            "pos_id" : target_employee.pos_id,
                            "dept_id" : target_employee.dept_id,
                            "user_pic" : target_employee.user_pic
                        };

                        replace_approver.push(employee_obj);

                        if( approver.include_parent == false ) break;
                    }
                }

                // 본인이 포함되어있다면 본인 이하(본인 포함)의 결재자를 전부 없앰
                const include_me_filter_approver_list = replace_approver.filter(item => item.user_id == this.user_id);
                if( include_me_filter_approver_list.length > 0 ) {
                    const target = include_me_filter_approver_list[0];
                    const index = replace_approver.indexOf(target);

                    // 본인만 제외하도록 임시 변경
                    replace_approver = replace_approver.filter(item => item.user_id != this.user_id);
                    // replace_approver = replace_approver.slice(index + 1, replace_approver.length);

                    for( const approver of replace_approver ) {
                        approver.seq = replace_approver.indexOf(approver) + 1;
                        approver.state = (replace_approver.indexOf(approver) + 1 == 1) ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT;
                    } 
                }

                target.approver = replace_approver;
                this.receive_template = target;
            }

        } catch(e) {
            throw e;
        }
    }

    /**
     * 기안서 조회
     */
    async getSelectedApprovalInfo() : Promise<void> {
        try {
            const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${this.approval_uid}`, API_METHOD.GET);
            
            console.log(response);

            if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.approval_info ) {
                throw new Error("기안서 조회 중 오류 발생");
            }

            this.doSetApproval?.(JSON.parse(JSON.stringify(response.data.data.approval_info)));
            this.approval.audit_created = new Date();
            this.$forceUpdate();

            if( this.approval.approval_type == 'VACATION' || this.approval.approval_type == 'BUSINESSTRIP' ) {
                window.setTimeout(() => {
                    this.$nextTick(() => {
                        $('#approval_vacation_date').trigger("blur");
                        this.datePickerInit()
                    });
                }, 1);
            }

            if( this.approval.contents.deputy_user_id != null && this.approval.contents.deputy_user_id > 0 && this.approval.contents.deputy_user_id == this.user_id ) {

                this.is_deputy = true;
                
                for( const employee of this.employees ) {
                    if( employee.user_id != this.approval.audit_create_id ) continue;
                    this.input_deputy_emp_data = this.createDeputyEmpData(employee);
                    this.selected_deputy_employee = JSON.parse(JSON.stringify(employee));
                }
            }

        } catch(e) {
            this.hodu_show_dialog('cancel', '기안서 조회 중 오류 발생', ['확인'], [() => { this.movePrevPage(); }]);
            this.hodu_error_process(e, false, false, true);
        }
    }

    /**
     * 타입 변경시 리셋
     */
    @Watch('approval.approval_type')
    changeApprovalType() : void {
        if( this.approval_uid != null ) return;
        this.reset();
    }

    /**
     * 휴가타입 변경
     */
    @Watch('selected_vacation_type_id')
    changeSelectedVacationTypeId() : void {
        // this.vacation_start = moment().toDate();
        // this.vacation_end = moment(this.vacation_start).toDate();

        // if( Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount)) > 1 ) {
        //     this.vacation_end = moment(this.vacation_start).set('date', this.vacation_start.getDate() +  Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount)) - 1).toDate();
        // }

        // this.vacation_start_text = `${this.hodu_date_to_format_string(this.vacation_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.vacation_start)}`;
        // this.vacation_end_text = `${this.hodu_date_to_format_string(this.vacation_end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.vacation_end)}`;
        
        // this.$nextTick(() => this.datePickerInit());

        // 반차 -> 직접입력, 직접입력 -> 직접입력은 변하지 않지만 직접 입력 -> 반차는 오늘날짜로 변경함
        // this.vacation_selected.splice(0, this.vacation_selected.length);
        // if( this.computedSelectedVacationType.vacation_type_daycount > 0 ) {
        //     this.vacation_selected.push(new Date());
        // }

        // 반차인데 두개 이상 선택된경우
        if( this.computedSelectedVacationType.vacation_type_daycount > 0 && this.vacation_selected.length > 1 ) {
            this.vacation_selected = this.vacation_selected.slice(0, 1);
        }

        this.makeVacationText();
    }

    /**
     * 출장타입 변경
     */
    @Watch('selected_business_trip_type_id')
    changeSelectedBusinessTripTypeId() : void {
        
        // this.business_trip_selected.splice(0, this.business_trip_selected.length);
        // if( this.computedSelectedBusinessTripType.business_trip_type_daycount > 0 ) {
        //     this.business_trip_selected.push(new Date());
        // }

        // 오전, 오후인데 두개 이상 선택된경우
        if( this.computedSelectedBusinessTripType.business_trip_type_daycount > 0 && this.business_trip_selected.length > 1 ) {
            this.business_trip_selected = this.business_trip_selected.slice(0, 1);
        }

        this.makeBusinessTripText();
    }

    /**
     * 휴일근무 타입 변경
     */
    @Watch('selected_holiday_work_type_id')
    changeSelectedHolidayWorkTypeId() : void {

        // 두개 이상 선택된경우
        if( this.computedSelectedHolidayWorkType.holiday_work_type_daycount > 0 && this.holiday_work_selected.length > 1 ) {
            this.holiday_work_selected = this.holiday_work_selected.slice(0, 1);
        }

        this.makeHolidayWorkText();
    }

    /**
     * 리셋
     */
    reset() : void {
        if( this.doSetApproval == null ) return;

        const today = new Date();

        // 일반기안
        if( this.is_deputy == false ) {
            
            this.doSetApproval({
                "approval_uid"  : "",
                "approval_rev"  : this.approval.approval_rev,
                "group_id"      : this.scope_group_id,
                "approval_type" : this.approval.approval_type,
                "approval_subtype_id" : 0,
                "user_id" : this.user_id,
                "dept_id" : this.approval.dept_id,
                "pos_id" : this.approval.pos_id,
                "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
                "receive_reference" : [],
                "contents" : {
                    "title" : "",
                    "comment" : "",
                    "color" : "#477FFF",
                    "files" : [],
                },
                "approval_state" : approval_enum.APPROVAL_STATE.ING,
                "approval_cur" : 0,
                "approval_total" : 0,
                "approval_document_num" : "",
                "approver" : [],
                "audit_created" : today
            });

        }
        //  대리기안
        else {
            
            this.doSetApproval({
                "approval_uid"  : "",
                "approval_rev"  : this.approval.approval_rev,
                "group_id"      : this.scope_group_id,
                "approval_type" : this.approval.approval_type,
                "approval_subtype_id" : 0,
                "user_id" : 0,
                "dept_id" : this.approval.dept_id,
                "pos_id" : this.approval.pos_id,
                "imp_seq" : approval_enum.APPROVAL_IMPORTANCE_FILTER.NORMAL,
                "receive_reference" : [],
                "contents" : {
                    "title" : "",
                    "comment" : "",
                    "color" : "#477FFF",
                    "files" : [],
                    "deputy_user_id" : this.user_id,
                    "input_deputy_emp_data" : "",
                },
                "approval_state" : approval_enum.APPROVAL_STATE.ING,
                "approval_cur" : 0,
                "approval_total" : 0,
                "approval_document_num" : "",
                "approver" : [],
                "audit_created" : today
            });
            
        }

        this.getTotalOrganizationData();

        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER ) {
            this.transactions.splice(0, this.transactions.length);
            this.transactions.push({
                "subject" : "",
                "date" : new Date(),
                "price" : "",
                "account" : "",
                "remark" : ""
            });
        }
    }

    /**
     * 부서 이름 반환
     * level 1 : 최상위 부서 이름 반환
     * level 2 : 최상위 부서 바로 아래의 부서 이름 하나만 반환
     * level n (n > 2) : level 2 / level 3 / level 4 ... 
     */
    getDepartmentName(dept_id : number) : string {
        try {

            if( dept_id == 0 ) return "미배정";
            
            let dept_name = "";
            const target = this.departments.filter(item => item.dept_id == dept_id)[0];

            let target_departments : any[] = [];
            for( const department of this.departments ) {
                if( target.dept_id_array.indexOf(department.dept_id) > -1 ) target_departments.push(department);
            }

            target_departments.sort((o1, o2) : number => {
                const o1_length = o1.dept_id_array.length;
                const o2_length = o2.dept_id_array.length;

                if( o1_length > o2_length ) return 1;
                else if( o1_length < o2_length ) return -1;
                return 0;
            });

            for( const department of target_departments ) {
                if( target_departments.length >= 2 && target_departments.indexOf(department) == 0 ) continue;

                if( dept_name.length > 0 ) dept_name += " / ";
                dept_name += department.dept_name;
            }
        
            return dept_name;
        } catch(e) {
            console.log(e);
            return "-";
        }
    }

    /**
     * 직급 이름 반환
     */
    getPositionName(pos_id : number) : string {
        try {
            const target = this.position.filter(item => item.pos_id == pos_id);
            if( target.length < 1 ) return "-";
            return target[0].pos_name;
        } catch(e) {
            return "-";
        }
    }

    /**
     * 수신 참조 이름 반환
     */
    getReceiveReference() : string {
        try {
            if( this.approval.receive_reference == null || this.approval.receive_reference.length < 1 ) {
                return "없음";
            } 

            let str = "";

            for( const ref of this.approval.receive_reference ) {
                if( str.length > 0 ) str += ", ";
                str += ref.user_name;
            }

            return str;

        } catch(e) {
            return "없음";
        }
    }

    /**
     * 수신참조 선택, 전자결재 결재자 선택 모달 띄우기
     */
    showApproverSelectModal(is_approver : boolean) : void {

        if( this.is_deputy == true && this.selected_deputy_employee == null ) return;

        this.doSetApproverSelectModalInfo?.({ 
            "show_modal" : true, 
            "is_approver" : is_approver,
            "is_default_setting" : false, 
            "is_self_approve" : false,
            "selected" : JSON.parse(JSON.stringify(
                is_approver == true ? this.approval.approver.filter(item => (this.is_deputy == true ? item.user_id != this.selected_deputy_employee.user_id : item.user_id != this.my_employee_info.user_id) ) 
                                    : this.approval.receive_reference
            )),
            "is_deputy" : this.is_deputy,
            "deputy_user_id" : this.is_deputy == true ? this.selected_deputy_employee.user_id : undefined,
            "callback" : (data : any) => {

                this.selected_approver_favorite_id = "";
                
                // 결재자 등록이였다면
                if( is_approver == true ) {
                    const drafter = this.approval.approver[0]; 
                    this.approval.approver.splice(0, this.approval.approver.length);
                    this.approval.approver = this.approval.approver.concat((drafter));
                    this.approval.approver = this.approval.approver.concat((data));

                    if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC ) {
                        this.is_self_approve = false;
                        this.self_approve_message = "";
                        if( this.approval.approver.length <= 1 ) {
                            
                            for( const template of this.added_templates ) {
    
                                console.log(template);
                                
                                let is_self_approve : boolean = false;
                                if( template.self_approver != null && template.self_approver.length > 0 ) {
                                    const target = template.self_approver.filter(approver => approver.user_id == this.user_id);
                                    is_self_approve = target.length > 0;
    
                                    console.log(target);
    
                                    if( is_self_approve == false ) {
                                        if( this.self_approve_message.length > 0 ) this.self_approve_message += "\n";
                                        this.self_approve_message += `${template.template_name} (${template.template_class_name})`;
                                    }
                                }
                                else {
                                    if( this.self_approve_message.length > 0 ) this.self_approve_message += "\n";
                                    this.self_approve_message += `${template.template_name} (${template.template_class_name})`;
                                }
    
                                if( is_self_approve == true ) {
                                    this.is_self_approve = true;
                                }
                            } 
                        }
                    }

                    else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.VACATION ) {

                        this.is_self_approve = false;

                        // 자가 결재 가능인지 검사
                        if( this.approval.approver.length <= 1 ) {
                            const self_approver = this.vacation_template.self_approver.filter(approver => approver.user_id == this.user_id);
                            if( self_approver.length > 0 ) this.is_self_approve = true;
                        }

                    }

                    else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.HOLIDAY_WORK ) {
                        this.is_self_approve = false;

                        // 자가 결재 가능인지 검사
                        if( this.approval.approver.length <= 1 ) {
                            const self_approver = this.holiday_work_template.self_approver.filter(approver => approver.user_id == this.user_id);
                            if( self_approver.length > 0 ) this.is_self_approve = true;
                        }
                    }

                    else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BUSINESSTRIP ) {

                        this.is_self_approve = false;

                        // 자가 결재 가능인지 검사
                        if( this.approval.approver.length <= 1 ) {
                            const self_approver = this.business_trip_template.self_approver.filter(approver => approver.user_id == this.user_id);
                            if( self_approver.length > 0 ) this.is_self_approve = true;
                        }

                    }

                    else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER ) {

                        this.is_self_approve = false;

                        // 자가 결재 가능인지 검사
                        if( this.approval.approver.length <= 1 ) {
                            const self_approver = this.cash_disbursement_voucher_template.self_approver.filter(approver => approver.user_id == this.user_id);
                            if( self_approver.length > 0 ) this.is_self_approve = true;
                        }

                    }

                    else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.FREE ) {

                        this.is_self_approve = false;

                        // 자가 결재 가능인지 검사
                        if( this.approval.approver.length <= 1 ) {
                            const self_approver = this.free_template.self_approver.filter(approver => approver.user_id == this.user_id);
                            if( self_approver.length > 0 ) this.is_self_approve = true;
                        }

                    }

                    return;
                }

                // 수신 참조 등록이였다면
                if( this.approval.receive_reference == null ) {
                    this.approval.receive_reference = data;
                    return;
                }

                this.approval.receive_reference.splice(0, this.approval.receive_reference.length);
                this.approval.receive_reference = this.approval.receive_reference.concat((data));
            } 
        });
    }

    /**
     * 결재자 삭제
     */
    deleteApprover(event, approver_list, target_approver) : void {
        const index = approver_list.indexOf(target_approver);
        approver_list.splice(index, 1);
        for( const approver of approver_list ) {
            approver.seq = approver_list.indexOf(approver);
            approver.state = approver.seq == 0 ? approval_enum.APPROVAL_APPROVER_STATE.REQUEST :
                             approver.seq == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING     : approval_enum.APPROVAL_APPROVER_STATE.WAIT;
        }

        this.selected_approver_favorite_id = "";
    }

    /**
     * 유저 이미지 에러
     */
    userImageError(event) : void {
        event.target.src = require('@/assets/images/contents/ic_approval_user.png');
    }

    /**
     * 전부 지우고 본인 데이터만 들어간다
     */
    allDeleteApprover() : void {
        if( this.approval.approver.length == 1 ) return;
        
        const comment = this.approval.approver[0].comment;
        
        this.approval.approver.splice(0, this.approval.approver.length); 
        this.approval.approver.push({
            rev : this.approval.approval_state == approval_enum.APPROVAL_STATE.REJECT ? this.approval.approval_rev + 1 : 0,
            seq : 0,
            state : approval_enum.APPROVAL_APPROVER_STATE.REQUEST,
            date : new Date(),
            comment : comment,
            user_id : this.user_id,
            pos_name : this.getPositionName(this.my_employee_info.pos_id),
            dept_name : this.getDepartmentName(this.my_employee_info.dept_id),
            user_name : this.my_employee_info.user_name,
            pos_id : this.my_employee_info.pos_id,
            dept_id : this.my_employee_info.dept_id,
            user_pic : this.my_employee_info.user_pic
        });

        this.selected_approver_favorite_id = "";
    }

    /**
     * 끝자리가 0이 안나오도록 가공
     */
    processDayCount(day_count : string) : string {

        if( typeof day_count != 'string' ) day_count = String(day_count);
        if( day_count.indexOf('.') == -1 ) return day_count;
        
        let is_find_except_zero = false;

        let process_day_count : string = "";

        const day_count_length : number = day_count.length;
        for( let i = (day_count_length - 1); i >= 0; i-- ) {
            const char = day_count[i];

            if( char == '0' && is_find_except_zero == true ) {
                process_day_count = `${char}${process_day_count}`;
                continue;
            }

            if( char != '0' ) {
                is_find_except_zero = true;
                process_day_count = `${char}${process_day_count}`;
            }
        }   

        // 마지막이 . 이면 제거
        if( process_day_count[process_day_count.length - 1] == '.' ) {
            process_day_count = process_day_count.substring(0, process_day_count.length - 1);
        }
        
        return process_day_count;
    }

    /**
     * 휴가 날짜 텍스트 생성
     */
    makeVacationText() : void {
        this.vacation_date_text = "";
        this.vacation_date_text_with_day = "";

        for( const selected_date of this.vacation_selected ) {
            if( this.vacation_date_text.length > 0 ) {
                this.vacation_date_text += ", ";
                this.vacation_date_text_with_day += ", ";
            }
            this.vacation_date_text += `${this.hodu_date_to_format_string(selected_date, 'YYYY.MM.DD')}`;
            this.vacation_date_text_with_day += `${this.hodu_date_to_format_string(selected_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(selected_date)}`;
        }

        // 반차라면 오전 오후 텍스트 추가
        if( this.vacation_date_text_with_day != '' && this.computedSelectedVacationType.vacation_type_daycount > 0 ) {
            if( this.computedSelectedVacationType.contents.amPm == 'AM' ) {
                // this.vacation_date_text += " 오전";
                this.vacation_date_text_with_day += " 오전";
            }
            else if( this.computedSelectedVacationType.contents.amPm == 'PM' ) {
                // this.vacation_date_text += " 오후";
                this.vacation_date_text_with_day += " 오후";
            }
        }
    }

    /**
     * 출장 날짜 텍스트 생성
     */
    makeBusinessTripText() : void {
        this.business_trip_date_text = "";
        this.business_trip_date_text_with_day = "";

        for( const selected_date of this.business_trip_selected ) {
            if( this.business_trip_date_text.length > 0 ) {
                this.business_trip_date_text += ", ";
                this.business_trip_date_text_with_day += ", ";
            }
            this.business_trip_date_text += `${this.hodu_date_to_format_string(selected_date, 'YYYY.MM.DD')}`;
            this.business_trip_date_text_with_day += `${this.hodu_date_to_format_string(selected_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(selected_date)}`;
        }

        // 반차라면 오전 오후 텍스트 추가
        if( this.business_trip_date_text_with_day != '' && this.computedSelectedBusinessTripType.business_trip_type_daycount > 0 ) {
            if( this.computedSelectedBusinessTripType.contents.amPm == 'AM' ) {
                // this.business_trip_date_text += " 오전";
                this.business_trip_date_text_with_day += " 오전";
            }
            else if( this.computedSelectedBusinessTripType.contents.amPm == 'PM' ) {
                // this.business_trip_date_text += " 오후";
                this.business_trip_date_text_with_day += " 오후";
            }
        }
    }

    makeHolidayWorkText() : void {
        this.holiday_work_date_text = "";
        this.holiday_work_date_text_with_day = "";

        for( const selected_date of this.holiday_work_selected ) {
            if( this.holiday_work_date_text.length > 0 ) {
                this.holiday_work_date_text += ", ";
                this.holiday_work_date_text_with_day += ", ";
            }
            this.holiday_work_date_text += `${this.hodu_date_to_format_string(selected_date, 'YYYY.MM.DD')}`;
            this.holiday_work_date_text_with_day += `${this.hodu_date_to_format_string(selected_date, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(selected_date)}`;
        }

        // 0.5일 근무는 오전 오후 텍스트 추가
        if( this.holiday_work_date_text_with_day != '' && this.computedSelectedHolidayWorkType.holiday_work_type_daycount > 0 ) {
            if( this.computedSelectedHolidayWorkType.contents.amPm == 'AM' ) {
                // this.holiday_work_date_text += " 오전";
                this.holiday_work_date_text_with_day += " 오전";
            }
            else if( this.computedSelectedHolidayWorkType.contents.amPm == 'PM' ) {
                // this.holiday_work_date_text += " 오후";
                this.holiday_work_date_text_with_day += " 오후";
            }
        }
    }

    /**
     * 날짜 선택
     */
    datePickerInit() : void {

        // @ts-ignore
        $('#approval_vacation_date').datepick({ 
            dateFormat: 'yyyy.mm.dd',
            multiSelect : 999,
            multiSeparator : ', ',
            fixedWeeks : true,
            showOtherMonths : true,
            selectOtherMonths : true,
            onClose : () => {
                $('#approval_vacation_date').trigger("blur");
            },
            onSelect : (selected_dates : Date[]) => {
                // console.log(selected_dates);

                if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.VACATION ) {

                    // 반차인데 한 날짜만 선택되어있는데 그 날짜를 비우면 그 날짜 그대로 다시 들어가도록
                    if( this.computedSelectedVacationType.vacation_type_daycount > 0 && selected_dates.length < 1 ) {
                        selected_dates = selected_dates.concat(this.vacation_selected);

                        // @ts-ignore
                        $('#approval_vacation_date').datepick('setDate', selected_dates);
                        return;
                    }
                    
                    // 반차라면 또는 첫 선택이라면 한 날짜만 선택 되도록
                    // if( this.computedSelectedVacationType.vacation_type_daycount > 0 || this.is_first_datepick == true ) {
                    if( this.computedSelectedVacationType.vacation_type_daycount > 0 ) {
                        const last_selected_date = JSON.parse(JSON.stringify(selected_dates[selected_dates.length - 1]));
                        selected_dates.splice(0, selected_dates.length);
                        selected_dates.push(new Date(last_selected_date));

                        this.vacation_selected.splice(0, this.vacation_selected.length);
                        this.vacation_selected = this.vacation_selected.concat(selected_dates);
                        this.makeVacationText();

                        // @ts-ignore
                        $('#approval_vacation_date').datepick('setDate', selected_dates);
                        // this.is_first_datepick = false;
                        return;
                    }

                    selected_dates.sort((o1, o2) : number => {
                        if( o1.getTime() < o2.getTime() ) return -1;
                        else if( o1.getTime() > o2.getTime() ) return 1;
                        return 0;
                    });

                    this.vacation_selected.splice(0, this.vacation_selected.length);
                    this.vacation_selected = this.vacation_selected.concat(selected_dates);
                    this.makeVacationText();

                }

                else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BUSINESSTRIP ) {
                    
                    // 오전 또는 오후인데 한 날짜만 선택되어있는데 그 날짜를 비우면 그 날짜 그대로 다시 들어가도록
                    if( this.computedSelectedBusinessTripType.business_trip_type_daycount > 0 && selected_dates.length < 1 ) {
                        selected_dates = selected_dates.concat(this.business_trip_selected);

                        // @ts-ignore
                        $('#approval_vacation_date').datepick('setDate', selected_dates);
                        return;
                    }

                    // 오전, 오후 출장이라면 또는 첫 선택이라면 한 날짜만 선택 되도록
                    // if( this.computedSelectedBusinessTripType.business_trip_type_daycount > 0 || this.is_first_datepick == true ) {
                    if( this.computedSelectedBusinessTripType.business_trip_type_daycount > 0 ) {
                        const last_selected_date = JSON.parse(JSON.stringify(selected_dates[selected_dates.length - 1]));
                        selected_dates.splice(0, selected_dates.length);
                        selected_dates.push(new Date(last_selected_date));

                        this.business_trip_selected.splice(0, this.business_trip_selected.length);
                        this.business_trip_selected = this.business_trip_selected.concat(selected_dates);
                        this.makeBusinessTripText();

                        // @ts-ignore
                        $('#approval_vacation_date').datepick('setDate', selected_dates);
                        // this.is_first_datepick = false;
                        return;
                    }

                    selected_dates.sort((o1, o2) : number => {
                        if( o1.getTime() < o2.getTime() ) return -1;
                        else if( o1.getTime() > o2.getTime() ) return 1;
                        return 0;
                    });

                    this.business_trip_selected.splice(0, this.business_trip_selected.length);
                    this.business_trip_selected = this.business_trip_selected.concat(selected_dates);
                    this.makeBusinessTripText();

                }

                else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.HOLIDAY_WORK ) {

                    // 오전 또는 오후인데 한 날짜만 선택되어있는데 날짜를 비우면 그 날짜 그대로 다시 들어가도록
                    if( this.computedSelectedHolidayWorkType.holiday_work_type_daycount > 0 && selected_dates.length < 1 ) {
                        selected_dates = selected_dates.concat(this.holiday_work_selected);

                        // @ts-ignore
                        $('#approval_vacation_date').datepick('setDate', selected_dates);
                        return;
                    }

                    // 오전, 오후 출장이라면 또는 첫 선택이라면 한 날짜만 선택 되도록
                    if( this.computedSelectedHolidayWorkType.holiday_work_type_daycount > 0 ) {
                        const last_selected_date = JSON.parse(JSON.stringify(selected_dates[selected_dates.length - 1]));
                        selected_dates.splice(0, selected_dates.length);
                        selected_dates.push(new Date(last_selected_date));

                        this.holiday_work_selected.splice(0, this.holiday_work_selected.length);
                        this.holiday_work_selected = this.holiday_work_selected.concat(selected_dates);
                        this.makeHolidayWorkText();

                        // @ts-ignore
                        $('#approval_vacation_date').datepick('setDate', selected_dates);
                        // this.is_first_datepick = false;
                        return;
                    }

                    selected_dates.sort((o1, o2) : number => {
                        if( o1.getTime() < o2.getTime() ) return -1;
                        else if( o1.getTime() > o2.getTime() ) return 1;
                        return 0;
                    });

                    this.holiday_work_selected.splice(0, this.holiday_work_selected.length);
                    this.holiday_work_selected = this.holiday_work_selected.concat(selected_dates);
                    this.makeHolidayWorkText();

                }
                
            }
        });
        

        // const datepicker_option = {
        //     inline: false,
        //     showOtherMonths: true,
        //     selectOtherMonths: true,
        //     dateFormat: 'yy-mm-dd',
        //     monthNames : ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        //     dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        //     yearSuffix : '.',
        //     blankSpace : '',
        //     changeYear : true,
        //     yearRange  : '1900:2050',
        //     onSelect: (dateText, inst) => {
        //         const selected_date = new Date(dateText);
        //         inst.input.val(`${this.hodu_date_to_format_string(selected_date, "YYYY.MM.DD")} ${this.getDayOfWeekByDate(selected_date)}`);

        //         const vacation_day_count : number = Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount));

        //         if( inst.id == 'approval_vacation_start' ) {
        //             this.vacation_start = moment(selected_date).toDate();
        //             this.vacation_end = moment(this.vacation_start).toDate();

        //             if( vacation_day_count > 1 ) {
        //                 this.vacation_end = moment(this.vacation_start).set('date', this.vacation_start.getDate() +  Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount)) - 1).toDate();
        //             }
        //         }

        //         else if( inst.id == 'approval_vacation_end' ) {
        //             this.vacation_end = moment(selected_date).toDate();
        //             this.vacation_start = moment(this.vacation_end).toDate();

        //             if( vacation_day_count > 1 ) {
        //                 this.vacation_start = moment(this.vacation_start).set('date', this.vacation_start.getDate() -  Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount)) + 1).toDate();
        //             }
        //         }

        //         // this.vacation_start_text = `${this.hodu_date_to_format_string(this.vacation_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.vacation_start)}`;
        //         // this.vacation_end_text = `${this.hodu_date_to_format_string(this.vacation_end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.vacation_end)}`;
        //     },
        // }

        // // @ts-ignore
        // try { $('#approval_vacation_start').datepicker(datepicker_option); } catch(e) { console.log(e); }

        // // @ts-ignore
        // try { $('#approval_vacation_end').datepicker(datepicker_option); } catch(e) { console.log(e); }
    }

    /**
     * 모든 파일 삭제
     */
    allDeleteFile() : void {
        this.all_files.splice(0, this.all_files.length);
        this.added_templates.splice(0, this.added_templates.length);
        this.approval.contents.files.splice(0, this.approval.contents.files.length);
        this.approval.approver = this.approval.approver.slice(0, 1);
    }

    /**
     * 파일 없이 템플릿 추가
     */
    addTemplateExceptFile() : void {
        this.doSetApprovalTemplateExceptFileModalInfo?.({
            show_modal : true,
            callback : async() => {
                
            },
        })
    }

    /**
     * 파일 추가
     */
    addFile() : void {

        // FREE
        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.FREE ) {
            $('#free_files').trigger('click');
            return;
        }

        this.doSetApprovalFileModalInfo?.({
            show_modal : true,
            is_create : false,
            callback : async(files : any[], js_files : File[]) => {
                try {
                    // 기본 결재자가 있는 첫번째 파일 기준으로 결재자를 등록, 전부 없으면 기본 결재자 기준으로 집어넣음
                    console.log(files);

                    this.added_templates = this.added_templates.concat(files);

                    let is_find_approver = false;

                    for( const file of files ) {
                        const js_file = js_files[files.indexOf(file)];

                        const content_file : approval_interface.t_approval_contents_file = {
                            name : file.contents.file_name,
                            url : file.contents.file_url,
                            mimeType : file.contents.mime_type,
                            size : file.contents.file_size,
                            date : new Date(),
                            hash : "",
                            keep_year : file.contents.keep_year,
                            template_class_id : file.template_id,
                            template_class_name : file.template_class_name,
                            template_id : file.template_class_id,
                            template_name : file.template_name,
                        }

                        this.approval.contents.files.push(content_file);

                        const file_index = this.approval.contents.files.indexOf(content_file);

                        this.all_files.push({
                            index : file_index,
                            file : file,
                            js_file : js_file,
                        });

                        // 기본 결재자
                        if( is_find_approver == false && this.approval.approver.length <= 1 && ( file.approver == null || file.approver.length > 0) ) {

                            const target = JSON.parse(JSON.stringify(file));
                            const taregt_approver = target.approver;

                            let replace_approver : approval_interface.t_approval_approver[] = [];
                            for( const approver of file.approver ) {
                                approver.comment = '';
                                approver.seq = this.approval.approver.length,
                                approver.state = this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                                approver.rev = 0;

                                if( approver.approver_type == 'EMPLOYEE' ) {
                                    replace_approver.push(approver);
                                    continue;
                                }

                                const dept_id_length = this.my_department_info.dept_id_array.length;
                                for( let i = (dept_id_length - 1); i >= 0; i-- ) {
                                    const dept_id = this.my_department_info.dept_id_array[i];
                                    const targets = this.employees.filter(employee => employee.dept_id == dept_id && employee.pos_id == approver.pos_id && employee.is_approver == true);

                                    if( targets.length < 1 ) continue;

                                    const target_employee = targets[0];

                                    const employee_obj : approval_interface.t_approval_approver = {
                                        "rev" : 0,
                                        "seq" : this.approval.approver.length,
                                        "state" : this.approval.approver.length == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT,
                                        "comment" : "",
                                        "user_id" : target_employee.user_id,
                                        "pos_name" : this.getPositionName(target_employee.pos_id),
                                        "dept_name" : this.getDepartmentName(target_employee.dept_id),
                                        "user_name" : target_employee.user_name,
                                        "pos_id" : target_employee.pos_id,
                                        "dept_id" : target_employee.dept_id,
                                        "user_pic" : target_employee.user_pic
                                    };

                                    replace_approver.push(employee_obj);

                                    if( approver.include_parent == false ) break;
                                }
                            }

                            // 본인이 포함되어있다면 본인 이하(본인 포함)의 결재자를 전부 없앰
                            const include_me_filter_approver_list = replace_approver.filter(item => item.user_id == this.user_id);
                            if( include_me_filter_approver_list.length > 0 ) {
                                const target = include_me_filter_approver_list[0];
                                const index = replace_approver.indexOf(target);

                                // 본인만 제외하도록 임시 변경
                                replace_approver = replace_approver.filter(item => item.user_id != this.user_id);
                                // replace_approver = replace_approver.slice(index + 1, replace_approver.length);

                                for( const approver of replace_approver ) {
                                    approver.seq = replace_approver.indexOf(approver) + 1;
                                    approver.state = (replace_approver.indexOf(approver) + 1 == 1) ? approval_enum.APPROVAL_APPROVER_STATE.ING : approval_enum.APPROVAL_APPROVER_STATE.WAIT;
                                } 
                            }

                            this.approval.approver = this.approval.approver.concat(replace_approver);
                            is_find_approver = true;
                        }
                    }

                    if( is_find_approver == false && this.default_template != null && this.approval.approver.length <= 1 ) {
                        for( const approver of this.default_template.approver ) {
                            
                            const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                            if( employee.length > 0 ) {
                                approver.user_name = employee[0].user_name;
                                approver.user_pic = employee[0].user_pic;
                            }
                            
                            this.approval.approver.push(JSON.parse(JSON.stringify(approver)));
                        }
                    }

                    // 끝까지 결재자가 없다면 자가결재 대상인지 체크한다
                    this.is_self_approve = false;
                    this.self_approve_message = "";
                    if( this.approval.approver.length <= 1 ) {
                        
                        for( const template of this.added_templates ) {

                            // for( const template_file of template ) {

                            console.log(template);
                            
                            let is_self_approve : boolean = false;
                            if( template.self_approver != null && template.self_approver.length > 0 ) {
                                const target = template.self_approver.filter(approver => approver.user_id == this.user_id);
                                is_self_approve = target.length > 0;

                                console.log(target);

                                if( is_self_approve == false ) {
                                    if( this.self_approve_message.length > 0 ) this.self_approve_message += "\n";
                                    this.self_approve_message += `${template.template_name} (${template.template_class_name})`;
                                }
                            }
                            else {
                                if( this.self_approve_message.length > 0 ) this.self_approve_message += "\n";
                                this.self_approve_message += `${template.template_name} (${template.template_class_name})`;
                            }

                            if( is_self_approve == true ) {
                                this.is_self_approve = true;
                            }

                            // }
                            
                        } 
                    }

                    // this.hodu_show_indicator();

                    // const form_data : FormData = new FormData();

                    // for ( const js_file of js_files ) {
                    //     form_data.append('file', js_file);
                    // }

                    // // 파일 임시 업로드
                    // const response = await this.hodu_temp_upload(form_data);

                    // console.log(response);

                    // if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.temp_files ) {
                    //     throw new Error("양식 파일 임시 업로드 실패");
                    // }

                    // if( this.approval.contents.files == null ) this.approval.contents.files = [];

                    // for( const temp_file of response.data.data.temp_files ) {
                    //     const temp_index = response.data.data.temp_files.indexOf(temp_file);
                    //     const file = files[temp_index];

                    //     const content_file : approval_interface.t_approval_contents_file = {
                    //         name : temp_file.name,
                    //         url : temp_file.url,
                    //         mimeType : temp_file.mimeType,
                    //         size : temp_file.size,
                    //         date : temp_file.date,
                    //         temp : temp_file.temp,
                    //         hash : "",
                    //         keep_year : file.contents.keep_year,
                    //         template_class_name : file.template_class_name,
                    //         template_name : file.template_name,
                    //     }

                    //     this.approval.contents.files.push(content_file);

                    //     const index = this.approval.contents.files.indexOf(temp_file);
                    //     this.all_files.push({ index : index, file : js_files });
                    // }

                } catch(e) {
                    throw e;
                } finally {
                    this.hodu_hide_indicator();
                }
            }
        })
    }

    /**
     * 파일 삭제
     */
    deleteFile(index : number) : void {

        this.approval.contents.files.splice(index, 1);
        this.added_templates.splice(index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const files_count : number = this.all_files.length;
        for( let i = (files_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.all_files[i].index > index ) {
                this.all_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.all_files[i].index == index ) {
                this.all_files.splice(i, 1);
                break;
            }
        }

        if( this.approval.contents.files.length == 0 ) {
            this.approval.approver = this.approval.approver.slice(0, 1);
        }
    }

    /**
     * 저장 전 유효성 검사
     */
    validationCheck(state : approval_enum.APPROVAL_STATE) : boolean {
        
        // 제목 체크
        if( this.approval.contents.title == null || this.approval.contents.title.trim().length < 1 ) {
            this.hodu_show_dialog('alert', "제목을 입력해주세요", ['확인'], [() => {
                $('#approval_create_title').focus();
            }]);
            return false;
        }

        if( state != approval_enum.APPROVAL_STATE.TEMP ) {
            
            // 내용 체크
            if( this.approval.contents.comment == null || this.approval.contents.comment.trim().length < 1 ) {
                this.hodu_show_dialog('alert', "내용을 입력해주세요", ['확인'], [() => {
                    $('#approval_create_comment').focus();
                }]);
                return false;
            }

            // 기안서일때
            if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC ) {
                // 파일 체크
                if( this.approval.contents.files == null || this.approval.contents.files.length < 1 ) {
                    this.hodu_show_dialog('alert', "파일을 추가해주세요", ['확인']);
                    return false;
                }
            }

            else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.VACATION ) {

                // 휴가일자 체크
                if( this.vacation_selected.length < 1 ) {
                    this.hodu_show_dialog('alert', "휴가일자를 선택해주세요", ['확인']);
                    return false;
                }

            }

            else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BUSINESSTRIP ) {

                // 출장일자 체크
                if( this.business_trip_selected.length < 1 ) {
                    this.hodu_show_dialog('alert', "출장일자를 선택해주세요", ['확인']);
                    return false;
                }

            }

            else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.HOLIDAY_WORK ) {

                // 휴일근무 체크
                if( this.holiday_work_selected.length < 1 ) {
                    this.hodu_show_dialog('alert', "근무일자를 선택해주세요", ['확인']);
                    return false;
                }

            }

            else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER ) {

                // 지출내역 내용 체크 (최소 하나는 들어있어야하고 중간에 항목 또는 금액이 비어있는게 있어서는 안됨)
                if( this.transactions.length < 1 ) {
                    this.hodu_show_dialog('alert', "지출내역을 입력해주세요", ['확인']);
                    return false;
                }

                for( const transaction of this.transactions ) {
                    const index = this.transactions.indexOf(transaction);
                    if( transaction.subject.trim().length < 1 ) {
                        this.hodu_show_dialog('alert', `${index + 1}번째 지출내역의 항목을 입력해주세요`, ['확인']);
                        return false;
                    }
                    if( transaction.price.trim().length < 1 ) {
                        this.hodu_show_dialog('alert', `${index + 1}번째 지출내역의 금액을 입력해주세요`, ['확인']);
                        return false;
                    }
                }

            }

            // 자가 결재 가능 여부 체크 (대리기안은 자가결재 기능 사용불가)
            if( this.is_deputy == false && this.is_self_approve == true && this.self_approve_message.length > 0 ) {
                
                if( confirm(`자가 결재가 불가능한 양식이 존재합니다\n\n[자가결재 불가능한 양식 목록]\n${this.self_approve_message}\n\n정말로 자가 결재를 진행하시겠습니까?`) == false ) {
                    return false
                }

            }

            else if( this.is_self_approve == false ) {
                // 결재자 체크
                if( this.approval.approver.length == 1 ) {
                    this.hodu_show_dialog('alert', "결재자를 선택해주세요", ['확인']);
                    return false;
                }
            }

            // const user_ids : number[] = [];
            // for( const approver of this.approval.approver ) {
            //     if( user_ids.indexOf(approver.user_id) > -1 ) {
            //         this.hodu_show_dialog('alert', "중복되는 결재자가 존재합니다", ['확인']);
            //         return false;
            //     }
            //     user_ids.push(approver.user_id);
            // }
            
        }

        return true;
    }

    /**
     * 저장
     */
    async save(state : approval_enum.APPROVAL_STATE) : Promise<void> {
        try {

            if( this.validationCheck(state) == false ) {
                return;
            }

            if( this.approval.approval_state != approval_enum.APPROVAL_STATE.REJECT && this.approval.approval_state != approval_enum.APPROVAL_STATE.TEMP ) {
                this.approval.approval_state = state;
            }

            this.approval.contents.title = this.approval.contents.title.trim();
            this.approval.contents.comment = this.approval.contents.comment.trim();

            this.approval['comment'] = {};

            // 자가 결재 (대리기안은 자가결재 기능 사용불가)
            if( this.is_self_approve == true && this.approval.approver.length == 1 ) {
                if( state == approval_enum.APPROVAL_STATE.TEMP ) {
                    this.approval.approver[0].rev = 1;
                    this.approval.approver[0].seq = 0;
                    this.approval.approver[0].state = approval_enum.APPROVAL_APPROVER_STATE.ING;
                    this.approval.approval_cur = 0;
                    this.approval.approval_total = 1;
                    this.approval.approval_state = approval_enum.APPROVAL_STATE.TEMP;
                }
                else {
                    this.approval.approver[0].rev = 1;
                    this.approval.approver[0].seq = 0;
                    this.approval.approver[0].state = approval_enum.APPROVAL_APPROVER_STATE.CONFIRM;
                    this.approval.approval_cur = 1;
                    this.approval.approval_total = 1;
                }                
            }

            else {
                for( const approver of this.approval.approver ) {
                    approver.rev = 1;
                    approver.seq = this.approval.approver.indexOf(approver);
                    approver.state = approver.seq == 0 ? approval_enum.APPROVAL_APPROVER_STATE.REQUEST :
                                    approver.seq == 1 ? approval_enum.APPROVAL_APPROVER_STATE.ING     : approval_enum.APPROVAL_APPROVER_STATE.WAIT;
                }

                this.approval.approval_cur = 0;
                this.approval.approval_total = this.approval.approver.length - 1;
            }
           
            if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.VACATION ) {
                this.approval.contents.vacation_type_id = Number(this.computedSelectedVacationType.vacation_type_id);
                this.approval.contents.vacation_type_name = this.computedSelectedVacationType.vacation_type_name;
                
                this.approval.contents.vacation_selected = [];
                for( const selected_date of this.vacation_selected ) {
                    this.approval.contents.vacation_selected.push(this.hodu_date_to_format_string(selected_date, 'YYYYMMDD'));
                }

                // this.approval.contents.vacation_start = moment(this.vacation_start).format('YYYYMMDD');
                // this.approval.contents.vacation_end = moment(this.vacation_end).format('YYYYMMDD');

                // 반차는 0.5일 그대로, 0일(직접입력)은 0으로 들어감
                this.approval.contents.vacation_type_daycount = this.computedSelectedVacationType.vacation_type_daycount;
                // if( 0 < this.computedSelectedVacationType.vacation_type_daycount && this.computedSelectedVacationType.vacation_type_daycount < 1 ) {    
                if( this.computedSelectedVacationType.vacation_type_daycount == 0.5 ) {    
                    this.approval.contents.vacation_am_pm = (this.computedSelectedVacationType.contents != null && this.computedSelectedVacationType.contents.amPm != null) ? this.computedSelectedVacationType.contents.amPm : '';
                }
            }

            else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BUSINESSTRIP ) {
                this.approval.contents.vacation_type_id = Number(this.computedSelectedBusinessTripType.business_trip_type_id);
                this.approval.contents.vacation_type_name = this.computedSelectedBusinessTripType.business_trip_type_name;
                
                this.approval.contents.vacation_selected = [];
                for( const selected_date of this.business_trip_selected ) {
                    this.approval.contents.vacation_selected.push(this.hodu_date_to_format_string(selected_date, 'YYYYMMDD'));
                }

                // 오전, 오후는 0.5일 그대로, 0일(직접입력)은 0으로 들어감
                this.approval.contents.vacation_type_daycount = this.computedSelectedBusinessTripType.business_trip_type_daycount;
                // if( 0 < this.computedSelectedBusinessTripType.business_trip_type_daycount && this.computedSelectedBusinessTripType.business_trip_type_daycount < 1 ) {    
                if( this.computedSelectedBusinessTripType.business_trip_type_daycount == 0.5 ) {    
                    this.approval.contents.vacation_am_pm = (this.computedSelectedBusinessTripType.contents != null && this.computedSelectedBusinessTripType.contents.amPm != null) ? this.computedSelectedBusinessTripType.contents.amPm : '';
                }
            }

            else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.HOLIDAY_WORK ) {
                this.approval.contents.vacation_type_id = Number(this.computedSelectedHolidayWorkType.holiday_work_type_id);
                this.approval.contents.vacation_type_name = this.computedSelectedHolidayWorkType.holiday_work_type_name;
                
                this.approval.contents.vacation_selected = [];
                for( const selected_date of this.holiday_work_selected ) {
                    this.approval.contents.vacation_selected.push(this.hodu_date_to_format_string(selected_date, 'YYYYMMDD'));
                }

                // 오전, 오후는 0.5일 그대로, 0일(직접입력)은 0으로 들어감
                this.approval.contents.vacation_type_daycount = this.computedSelectedHolidayWorkType.holiday_work_type_daycount;
                if( this.computedSelectedHolidayWorkType.holiday_work_type_daycount == 0.5 ) {    
                    this.approval.contents.vacation_am_pm = (this.computedSelectedHolidayWorkType.contents != null && this.computedSelectedHolidayWorkType.contents.amPm != null) ? this.computedSelectedHolidayWorkType.contents.amPm : '';
                }
            }

            else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER ) {
                this.approval.contents.transactions = this.transactions;

                for( const transaction of this.approval.contents.transactions ) {
                    transaction.price = transaction.price.replaceAll(",", "");
                }
            }

            // 파일 임시업로드
            if( this.all_files.length > 0 ) {
                this.hodu_show_indicator();

                const form_data : FormData = new FormData();

                for ( const all_file of this.all_files ) {
                    form_data.append('file', all_file.js_file);
                }

                // 파일 임시 업로드
                const response = await this.hodu_temp_upload(form_data);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data || !response.data.data.temp_files ) {
                    throw new Error("기안서 파일 임시 업로드 실패");
                }

                if( (this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC || this.approval.approval_type == approval_enum.APPROVAL_TYPE.FREE) && this.approval.contents.files == null ) this.approval.contents.files = [];
                if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER && this.approval.contents.transaction_files == null ) this.approval.contents.transaction_files = [];

                for( const temp_file of response.data.data.temp_files ) {
                    const temp_index = response.data.data.temp_files.indexOf(temp_file);
                    const target_index = this.all_files[temp_index].index;
                    let target : any = null;
                    if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BASIC || this.approval.approval_type == approval_enum.APPROVAL_TYPE.FREE ) {
                        target = this.approval.contents.files[target_index];
                    }
                    else if( this.approval.contents.transaction_files != null && this.approval.approval_type == approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER ) {
                        target = this.approval.contents.transaction_files[target_index];
                    }
                    target.date = temp_file.date;
                    target.temp = temp_file.temp;
                    target.url  = temp_file.url;
                }
            }

            if( this.approval_uid == null ) {

                // 자가 결재시 state = COMPLETE로 설정
                if( this.is_self_approve == true && state != approval_enum.APPROVAL_STATE.TEMP && this.approval.approver.length == 1 ) {
                    this.approval.approval_state = approval_enum.APPROVAL_STATE.COMPLETE;
                }

                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval`, API_METHOD.POST, this.approval);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("기안서 등록 중 오류 발생");
                }
            }

            // 반려된 기안서 재등록
            else if( this.approval_uid != null && this.approval.approval_state == approval_enum.APPROVAL_STATE.REJECT ) {
                
                // 자가 결재시 state = COMPLETE로 설정 (반려된 기안서는 대리기안 사용 불가)
                if( this.is_self_approve == true && state != approval_enum.APPROVAL_STATE.TEMP && this.approval.approver.length == 1 ) {
                    this.approval.approval_state = approval_enum.APPROVAL_STATE.COMPLETE;
                }
                else {
                    this.approval.approval_state = approval_enum.APPROVAL_STATE.ING;
                }
                
                for( const approver of this.approval.approver ) {
                    approver.rev = this.approval.approval_rev + 1;
                    if( this.approval.approver.indexOf(approver) > 0 ) approver.comment = "";
                }

                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${this.approval_uid}`, API_METHOD.POST, this.approval);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("기안서 등록 중 오류 발생");
                }
            }

            // 임시 저장된 문서 임시저장
            else if( this.approval_uid != null && this.approval.approval_state == approval_enum.APPROVAL_STATE.TEMP && state == approval_enum.APPROVAL_STATE.TEMP ) {
                
                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${this.approval_uid}`, API_METHOD.PUT, this.approval);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("기안서 임시 저장 중 오류 발생");
                }

            }

            // 임시 저장된 문서 저장
            else if( this.approval_uid != null && this.approval.approval_state == approval_enum.APPROVAL_STATE.TEMP && state != approval_enum.APPROVAL_STATE.TEMP ) {
                
                // 자가 결재시 state = COMPLETE로 설정 (대리기안은 자가결재 기능 사용불가)
                if( this.is_deputy == false && this.is_self_approve == true && this.approval.approver.length == 1 ) {
                    this.approval.approval_state = approval_enum.APPROVAL_STATE.COMPLETE;
                }
                else {
                    this.approval.approval_state = approval_enum.APPROVAL_STATE.ING;
                }

                // console.log(this.approval.approval_state);
                // console.log(this.approval.approval_cur);
                // console.log(this.approval.approval_total);

                const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/${this.approval_uid}`, API_METHOD.PUT, this.approval);

                console.log(response);

                if( !response || !this.isHttpStatusSuccess(response.status) ) {
                    throw new Error("기안서 등록 중 오류 발생");
                }
            }

            this.hodu_router_push(`/GROUP/${this.scope_group_id}/approval?${new Date().getTime()}`);

        } catch(exception) {

            const e = (exception as any);

            // 커스텀 오류
            if( e.response != null && e.response.data != null ) {
                
                switch(e.response.data.data.error_type) {
                    case "valid_check":
                    case "pdf_error":
                        this.hodu_show_dialog("cancel", e.response.data.msg, ['확인']);
                        break;
                }

            }
            else {
                this.hodu_show_dialog("cancel", "기안서 등록 중 오류 발생", ['확인']);
            }
            
            this.hodu_error_process(e, false, false, true);

        } finally {
            this.hodu_hide_indicator();
        }
    }

    /**
     * 작성시 연차수 표시여부
     */
    isDenoteAnnualCount() : boolean {
        const feature = this.get_group_feature(this.scope_group_id);
        return feature != null ? feature['approval']['is_denote_annual_count'] : false;
    }

    /**
     * 지출내역 추가
     */
    addTransaction() {
        this.transactions.push({
            "subject" : "",
            "date" : new Date(),
            "price" : "",
            "account" : "",
            "remark" : ""
        });
    }

    /**
     * 지출내역 삭제
     */
    removeTransaction(transactions : any[], transaction : any) {
        const index = transactions.indexOf(transaction);

        if( index > - 1 ) {
            transactions.splice(index, 1);
        }
    }

    /**
     * 지출일자 설정
     */
    selectTransactionDate(transactions : any[], transaction : any) {
        const index = transactions.indexOf(transaction);

        if( index > - 1 ) {

            const date = transaction.date ? new Date(transaction.date) : new Date(); 

            this.doSetCommonDatepickerModalInfo?.({
                show_modal : true,
                default_date : date,
                callback : async(selected_date : Date) => {
                    const new_transaction = JSON.parse(JSON.stringify(transaction));
                    new_transaction.date = selected_date;
                    transactions.splice(index, 1, new_transaction);
                }
            })
        }
    }

    /**
     * 모든 파일 삭제
     */
    allDeleteTransactionFile() : void {
        this.all_files.splice(0, this.all_files.length);
        if( this.approval.contents.transaction_files != null ) this.approval.contents.transaction_files.splice(0, this.approval.contents.transaction_files.length);
        this.$forceUpdate();
    }

    /**
     * 파일 추가
     */
    addTransactionFileButtonClick() : void {
        $('#transaction_files').trigger('click');
    }

    addTransactionFile(event) : void {

        const files : File[] = event.target.files;

        if( this.approval.contents.transaction_files == null ){
            this.approval.contents.transaction_files = [];
        }

        const file_count : number = files.length;
        const event_files : t_event_file[] = [];

        const is_premium = this.is_premium_group('GROUP', this.approval.group_id);

        let max_size : number = is_premium ? this.DEFAULT_FILE_MAX_SIZE : this.NORMAL_GROUP_FILE_MAX_SIZE;
        let max_size_text : string = is_premium ? this.DEFAULT_FILE_MAX_SIZE_TEXT : this.NORMAL_GROUP_FILE_MAX_SIZE_TEXT;

        // 파일 용량 체크 후 t_event_file로 만들어 push
        for( let i = 0; i < file_count; i++ ) {
            if( files[i].size > max_size ) {
                alert(`${max_size_text} 이하의 파일만 업로드 가능 합니다`);
                $('#transaction_files').val("");
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#transaction_files').val("");
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#transaction_files').val("");
                return;
            }

            const file = {
                name: files[i].name,     // 원본 파일명
                mimeType: files[i].type, // MIME TYPE
                url: "",                 // 파일 경로
                size: files[i].size,     // 파일 크기
                date: new Date()         // 저장 날짜
            };
            
            event_files.push(file);

            // 업로드용 파일 객체 담기
            this.all_files.push({
                index : ( this.approval.contents.transaction_files.length + i ),
                file : file, 
                js_file : files[i]
            });
        }

        // 배열 합치기
        this.approval.contents.transaction_files = this.approval.contents.transaction_files.concat(event_files);

        // 파일 input 비우기
        $('#transaction_files').val("");
        this.$forceUpdate();
    }

    /**
     * 파일 삭제
     */
    deleteTransactionFile(index : number) : void {

        if( this.approval.contents.transaction_files == null ) return;

        this.approval.contents.transaction_files.splice(index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const files_count : number = this.all_files.length;
        for( let i = (files_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.all_files[i].index > index ) {
                this.all_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.all_files[i].index == index ) {
                this.all_files.splice(i, 1);
                break;
            }
        }
        this.$forceUpdate();
    }

    addFreeFile(event) : void {

        const files : File[] = event.target.files;

        if( this.approval.contents.files == null ){
            this.approval.contents.files = [];
        }

        const file_count : number = files.length;
        const event_files : t_event_file[] = [];

        const is_premium = this.is_premium_group('GROUP', this.approval.group_id);

        let max_size : number = is_premium ? this.DEFAULT_FILE_MAX_SIZE : this.NORMAL_GROUP_FILE_MAX_SIZE;
        let max_size_text : string = is_premium ? this.DEFAULT_FILE_MAX_SIZE_TEXT : this.NORMAL_GROUP_FILE_MAX_SIZE_TEXT;

        // 파일 용량 체크 후 t_event_file로 만들어 push
        for( let i = 0; i < file_count; i++ ) {
            if( files[i].size > max_size ) {
                alert(`${max_size_text} 이하의 파일만 업로드 가능 합니다`);
                $('#transaction_files').val("");
                return;
            }

            // 확장자가 없는 파일
            if( files[i].name.lastIndexOf('.') == -1 ) {
                alert("확장자가 있는 파일만 업로드 가능 합니다");
                $('#transaction_files').val("");
                return;
            } 
            
            // 확장자 제한 확인
            if( this.file_extension_forbidden.indexOf(files[i].name.substring(files[i].name.lastIndexOf('.')).toUpperCase()) > -1 ) {
                alert(`${ files[i].name.substring(files[i].name.lastIndexOf('.') + 1) } 파일은 업로드 할 수 없습니다`);
                $('#transaction_files').val("");
                return;
            }

            const file = {
                name: files[i].name,     // 원본 파일명
                mimeType: files[i].type, // MIME TYPE
                url: "",                 // 파일 경로
                size: files[i].size,     // 파일 크기
                date: new Date()         // 저장 날짜
            };
            
            event_files.push(file);

            // 업로드용 파일 객체 담기
            this.all_files.push({
                index : ( this.approval.contents.files.length + i ),
                file : file, 
                js_file : files[i]
            });
        }

        // 배열 합치기
        this.approval.contents.files = this.approval.contents.files.concat(JSON.parse(JSON.stringify(event_files)));

        // 파일 input 비우기
        $('#free_files').val("");
        this.$forceUpdate();
    }

    /**
     * 파일 삭제
     */
    deleteFreeFile(index : number) : void {

        if( this.approval.contents.files == null ) return;

        this.approval.contents.files.splice(index, 1);

        // 새로 올리는 파일을 검사해서 같이 삭제한다
        const files_count : number = this.all_files.length;
        for( let i = (files_count - 1); i >= 0; i--) {
            
            // 삭제하는 인덱스보다 큰 객체는 index를 1 줄인다
            if( this.all_files[i].index > index ) {
                this.all_files[i].index--;
            }

            // 삭제할 인덱스가 보이면 삭제후 나간다
            else if( this.all_files[i].index == index ) {
                this.all_files.splice(i, 1);
                break;
            }
        }
        this.$forceUpdate();
    }

    // 결재자 즐겨찾기 선택에 따른 결재자, 수신참조 변경이 필요하다 기본결재자 선택시에는 기본결재자로 바뀌어야함
    changeApproverFavorite(event) {
        try {
            
            if( this.selected_approver_favorite_id == "" ) {
                this.setDefaultApprover();
                return;
            }

            const selected_favorite_filter = this.favorites.filter(favorite => favorite.favorite_id == this.selected_approver_favorite_id);

            if ( selected_favorite_filter.length < 1 ) return;

            const selected_favorite = selected_favorite_filter[0];

            if( this.approval.approver == null ) this.approval.approver = [];
            this.approval.approver.splice(0, this.approval.approver.length);

            this.approval.approver.push({
                rev : this.approval.approval_state == approval_enum.APPROVAL_STATE.REJECT ? this.approval.approval_rev + 1 : 0,
                seq : 0,
                state : approval_enum.APPROVAL_APPROVER_STATE.REQUEST,
                date : new Date(),
                comment : "",
                user_id : this.user_id,
                pos_name : this.getPositionName(this.my_employee_info.pos_id),
                dept_name : this.getDepartmentName(this.my_employee_info.dept_id),
                user_name : this.my_employee_info.user_name,
                pos_id : this.my_employee_info.pos_id,
                dept_id : this.my_employee_info.dept_id,
                user_pic : this.my_employee_info.user_pic,
            });

            for( const approver of selected_favorite.approver ) approver.dept_name = ((approver.dept_id == 0) ? '미배정' : approver.dept_name);

            this.approval.approver = this.approval.approver.concat(selected_favorite.approver);

            if( this.approval.receive_reference == null ) this.approval.receive_reference = [];

            for( const recv of selected_favorite.selected_favorite ) recv.dept_name = ((recv.dept_id == 0) ? '미배정' : recv.dept_name);

            this.approval.receive_reference.splice(0, this.approval.receive_reference.length);
            this.approval.receive_reference = this.approval.receive_reference.concat(selected_favorite.receive_reference);

        } catch(e) {
            this.hodu_error_process(e, false, false, true);
        }
    }
    
    setDefaultApprover() {

        try {

            if( this.is_deputy == true ) {
                this.approval.approver.splice(0, this.approval.approver.length);
                this.approval.receive_reference?.splice(0, this.approval.receive_reference.length);
                return;
            }

            // 첫 approver 데이터는 요청자로 채운다
            this.approval.approver.splice(0, this.approval.approver.length);
            this.approval.approver.push({
                rev : this.approval.approval_state == approval_enum.APPROVAL_STATE.REJECT ? this.approval.approval_rev + 1 : 0,
                seq : 0,
                state : approval_enum.APPROVAL_APPROVER_STATE.REQUEST,
                date : new Date(),
                comment : "",
                user_id : this.user_id,
                pos_name : this.getPositionName(this.my_employee_info.pos_id),
                dept_name : this.getDepartmentName(this.my_employee_info.dept_id),
                user_name : this.my_employee_info.user_name,
                pos_id : this.my_employee_info.pos_id,
                dept_id : this.my_employee_info.dept_id,
                user_pic : this.my_employee_info.user_pic,
            });

            // 기본 수신참조 
            if( this.receive_template != null && this.receive_template.approver.length > 0 ) {
                
                if( this.approval.receive_reference == null ) this.approval.receive_reference = [];
                this.approval.receive_reference.splice(0, this.approval.receive_reference.length);

                for( const approver of this.receive_template.approver ) {

                    const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                    if( employee.length > 0 ) {
                        approver.user_name = employee[0].user_name;
                        approver.user_pic = employee[0].user_pic;
                    }

                    this.approval.receive_reference.push({
                        user_id : approver.user_id,
                        pos_id : approver.pos_id,
                        dept_id : approver.dept_id,
                        pos_name : approver.pos_name,
                        dept_name : approver.dept_name,
                        user_name : approver.user_name,
                        user_pic : approver.user_pic
                    });
                }
            }
            
            // 휴가원으로 설정 된 경우
            if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.VACATION ) {
                
                // 제목을 '휴가원'으로 지정하고 변경 불가능
                this.approval.contents.title = '휴가원';
                
                if( this.vacation_types == null || this.vacation_types.length < 1 ) {
                    this.hodu_show_dialog("cancel", "휴가 종류가 설정 되어 있지 않습니다\n관리자에게 문의하세요", ['확인'], [() => { this.movePrevPage(); }]);
                    return;
                }
                
                this.selected_vacation_type_id = this.vacation_types[0].vacation_type_id;
                
                // this.vacation_start = moment().toDate();
                // this.vacation_end = moment(this.vacation_start).toDate();

                // if( Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount)) > 1 ) {
                    // this.vacation_end = moment(this.vacation_start).set('date', this.vacation_start.getDate() +  Number(this.processDayCount(this.computedSelectedVacationType.vacation_type_daycount)) - 1).toDate();
                // }
                
                // 초기값 없앰
                this.vacation_selected.splice(0, this.vacation_selected.length);
                // this.vacation_selected.push(new Date());

                this.makeVacationText();

                // this.vacation_start_text = `${this.hodu_date_to_format_string(this.vacation_start, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.vacation_start)}`;
                // this.vacation_end_text = `${this.hodu_date_to_format_string(this.vacation_end, 'YYYY.MM.DD')} ${this.getDayOfWeekByDate(this.vacation_end)}`;
                
                this.$nextTick(() => this.datePickerInit());

                // 휴가원의 기본결재자 검사후 결재자로 등록
                if( this.vacation_template != null && this.vacation_template.approver.length > 0) {
                    for( const approver of this.vacation_template.approver ) {
                        
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }

                        this.approval.approver.push(JSON.parse(JSON.stringify(approver)));
                    }
                }

                // 전체 기본결재자 검사후 결재자로 등록
                else if( this.default_template != null && this.default_template.approver.length > 0) {
                    for( const approver of this.default_template.approver ) {
                        
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }

                        this.approval.approver.push(JSON.parse(JSON.stringify(approver)));
                    }
                }
            }

            // 출장신청서
            else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BUSINESSTRIP ) {
                
                // 제목을 '출장신청서'로 지정하고 변경 불가능
                this.approval.contents.title = '출장신청서';
                this.selected_business_trip_type_id = "1";

                // 초기값 없앰
                this.business_trip_selected.splice(0, this.business_trip_selected.length);
                // this.business_trip_selected.push(new Date());

                this.makeBusinessTripText();

                this.$nextTick(() => this.datePickerInit());

                // 출장신청서 기본결재자 검사후 결재자로 등록
                if( this.business_trip_template != null && this.business_trip_template.approver.length > 0) {
                    for( const approver of this.business_trip_template.approver ) {
                        
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }

                        this.approval.approver.push(JSON.parse(JSON.stringify(approver)));
                    }
                }

                // 전체 기본결재자 검사후 결재자로 등록
                else if( this.default_template != null && this.default_template.approver.length > 0) {
                    for( const approver of this.default_template.approver ) {
                        
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }

                        this.approval.approver.push(JSON.parse(JSON.stringify(approver)));
                    }
                }
            }
            
            // 휴일근무
            else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.HOLIDAY_WORK ) {
                // 제목을 '휴일근무'로 지정하고 변경 불가능
                this.approval.contents.title = '휴일근무';
                
                if( this.holiday_work_types == null || this.holiday_work_types.length < 1 ) {
                    this.hodu_show_dialog("cancel", "휴일근무 종류가 설정 되어 있지 않습니다\n관리자에게 문의하세요", ['확인'], [() => { this.movePrevPage(); }]);
                    return;
                }
                
                this.selected_holiday_work_type_id = this.holiday_work_types[0].holiday_work_type_id;
                
                // 초기값 없앰
                this.holiday_work_selected.splice(0, this.holiday_work_selected.length);

                this.makeHolidayWorkText();
                
                this.$nextTick(() => this.datePickerInit());

                // 휴일근무의 기본결재자 검사후 결재자로 등록
                if( this.holiday_work_template != null && this.holiday_work_template.approver.length > 0) {
                    for( const approver of this.holiday_work_template.approver ) {
                        
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }
                        
                        this.approval.approver.push(JSON.parse(JSON.stringify(approver)));
                    }
                }

                // 전체 기본결재자 검사후 결재자로 등록
                else if( this.default_template != null && this.default_template.approver.length > 0) {
                    for( const approver of this.default_template.approver ) {
                        
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }

                        this.approval.approver.push(JSON.parse(JSON.stringify(approver)));
                    }
                }
            }
            
            // 지출결의서
            else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.CASH_DISBURSEMENT_VOUCHER ) {

                // 제목을 '지출결의서'로 지정하고 변경 불가능
                // this.approval.contents.title = '지출결의서';

                if( this.approval.contents.transaction_files == null ) {
                    this.approval.contents.transaction_files = [];
                }
                this.approval.contents.transaction_files.splice(0, this.approval.contents.transaction_files.length);

                // 출장신청서 기본결재자 검사후 결재자로 등록
                if( this.cash_disbursement_voucher_template != null && this.cash_disbursement_voucher_template.approver.length > 0) {
                    for( const approver of this.cash_disbursement_voucher_template.approver ) {
                        
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }

                        this.approval.approver.push(JSON.parse(JSON.stringify(approver)));
                    }
                }

                // 전체 기본결재자 검사후 결재자로 등록
                else if( this.default_template != null && this.default_template.approver.length > 0) {
                    for( const approver of this.default_template.approver ) {
                        
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }

                        this.approval.approver.push(JSON.parse(JSON.stringify(approver)));
                    }
                }
            }

            // 무양식 기안서
            else if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.FREE ) {

                // 기안서 결재자로 등록
                if( this.free_template != null && this.free_template.approver.length > 0) {
                    for( const approver of this.free_template.approver ) {
                        
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }

                        this.approval.approver.push(JSON.parse(JSON.stringify(approver)));
                    }
                }

                // 전체 기본결재자
                else if( this.default_template != null && this.default_template.approver.length > 0) {
                    for( const approver of this.default_template.approver ) {
                        
                        const employee = this.employees.filter(employee => employee.user_id == approver.user_id);
                        if( employee.length > 0 ) {
                            approver.user_name = employee[0].user_name;
                            approver.user_pic = employee[0].user_pic;
                        }

                        this.approval.approver.push(JSON.parse(JSON.stringify(approver)));
                    }
                }
            }
            
            // 기안서인 경우
            else {
                if( this.templates == null || this.templates.filter(item => item['files'] != null && item['files'].length > 0 && (item['template_state'] == '0' || item['template_state'] == '10') ).length < 1 ) {
                    this.hodu_show_dialog("cancel", "기안서 양식이 설정 되어 있지 않습니다\n관리자에게 문의하세요", ['확인'], [() => { this.movePrevPage(); }]);
                    return;
                }
            }

            for( const recv of (this.approval.receive_reference ?? []) ) recv.dept_name = ((recv.dept_id == 0) ? '미배정' : recv.dept_name);
            for( const approver of this.approval.approver ) approver.dept_name = ((approver.dept_id == 0) ? '미배정' : approver.dept_name);
            this.$forceUpdate();

        } catch(e) {
            throw e;
        }
    }

    createApprovalApproverFavoriteModalInfo() {

        this.doSetApprovalApproverFavoriteModalInfo?.({
            show_modal : true,
            approver : this.approval.approver ? this.approval.approver.slice(1) : [],
            receive_reference : this.approval.receive_reference ? this.approval.receive_reference : [],
            callback : async(favorite_name, approver, receive_reference) => {
                try {

                    const body = {
                        "favorite_name" : favorite_name,
                        "approver" : approver,
                        "receive_reference" : receive_reference
                    }

                    console.log(body);

                    // 추가
                    const response = await this.hodu_api_call(`api/v1/groups/${this.scope_group_id}/approval/approver/favorite`, API_METHOD.POST, body);

                    if( response == null || !this.isHttpStatusSuccess(response.status) || !response.data || !response.data.data ) {
                        throw new Error("결재자 즐겨찾기 추가 오류");
                    }

                    const favorite = response.data.data.favorite;
                    this.selected_approver_favorite_id = favorite.favorite_id;

                    this.approval.approver.splice(0, this.approval.approver.length);

                    this.approval.approver.push({
                        rev : this.approval.approval_state == approval_enum.APPROVAL_STATE.REJECT ? this.approval.approval_rev + 1 : 0,
                        seq : 0,
                        state : approval_enum.APPROVAL_APPROVER_STATE.REQUEST,
                        date : new Date(),
                        comment : "",
                        user_id : this.user_id,
                        pos_name : this.getPositionName(this.my_employee_info.pos_id),
                        dept_name : this.getDepartmentName(this.my_employee_info.dept_id),
                        user_name : this.my_employee_info.user_name,
                        pos_id : this.my_employee_info.pos_id,
                        dept_id : this.my_employee_info.dept_id,
                        user_pic : this.my_employee_info.user_pic,
                    });

                    this.approval.approver = this.approval.approver.concat(favorite.approver);

                    if( this.approval.receive_reference == null ) this.approval.receive_reference = [];
                    this.approval.receive_reference.splice(0, this.approval.receive_reference.length);
                    this.approval.receive_reference = this.approval.receive_reference.concat(favorite.receive_reference);
                    
                    this.getApprovalApproverFavorites();

                } catch(e) {
                    this.hodu_error_process(e, false, false, true);
                    this.hodu_show_dialog("cancel", "결재자 즐겨찾기 추가 실패", ['확인']);
                }
            }
        });

    }

    /**
     * 대리기안 변경
     */
    changeDeputy() {
        let copy_approval = JSON.parse(JSON.stringify(this.approval));

        // 일반기안
        if( this.is_deputy == false ) {
            copy_approval.user_id = this.user_id;
            copy_approval.contents.deputy_user_id = undefined;
            this.doSetApproval?.(copy_approval);
        }
        //  대리기안
        else {
            copy_approval.user_id = 0;
            copy_approval.contents.deputy_user_id = this.user_id;
            this.doSetApproval?.(copy_approval);
        }

        this.getTotalOrganizationData();
    }

    inputDeputyEmpData(event) {
        this.input_deputy_emp_data = event.target.value;

        this.selected_deputy_employee = null;
        for( const employee of this.employees ) {
            if( this.createDeputyEmpData(employee) == this.input_deputy_emp_data ) {
                this.selected_deputy_employee = JSON.parse(JSON.stringify(employee));
                break;
            }
        }

        let copy_approval = JSON.parse(JSON.stringify(this.approval));

        if( this.selected_deputy_employee == null ) {
            copy_approval.user_id = 0;
            copy_approval.contents.input_deputy_emp_data = "";
            this.doSetApproval?.(copy_approval);
            this.approval.approver.splice(0, this.approval.approver.length);
            this.is_self_approve = false;
            return;
        }

        copy_approval.user_id = this.selected_deputy_employee.user_id;
        copy_approval.contents.input_deputy_emp_data = this.input_deputy_emp_data;
        this.doSetApproval?.(copy_approval);

        this.approval.approver.splice(0, this.approval.approver.length);
        this.approval.approver.push({
            rev : 0,
            seq : 0,
            state : approval_enum.APPROVAL_APPROVER_STATE.REQUEST,
            date : new Date(),
            comment : "",
            user_id : this.selected_deputy_employee.user_id,
            pos_name : this.getPositionName(this.selected_deputy_employee.pos_id),
            dept_name : this.getDepartmentName(this.selected_deputy_employee.dept_id),
            user_name : this.selected_deputy_employee.user_name,
            pos_id : this.selected_deputy_employee.pos_id,
            dept_id : this.selected_deputy_employee.dept_id,
            user_pic : this.selected_deputy_employee.user_pic,
        });

        this.is_self_approve = false;
        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.VACATION && this.vacation_template != null ) {
            const self_approver = this.vacation_template.self_approver.filter(approver => approver.user_id == this.selected_deputy_employee.user_id);
            if( self_approver.length > 0 ) this.is_self_approve = true;
        }

        // 출장신청서일때만 체크
        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.BUSINESSTRIP && this.business_trip_template != null ) {
            const self_approver = this.business_trip_template.self_approver.filter(approver => approver.user_id == this.selected_deputy_employee.user_id);
            if( self_approver.length > 0 ) this.is_self_approve = true;
        }

        // 휴일근무일때만 체크
        if( this.approval.approval_type == approval_enum.APPROVAL_TYPE.HOLIDAY_WORK && this.holiday_work_template != null ) {
            const self_approver = this.holiday_work_template.self_approver.filter(approver => approver.user_id == this.selected_deputy_employee.user_id);
            if( self_approver.length > 0 ) this.is_self_approve = true;
        }
    }

    createDeputyEmpData(employee) {
        if( employee == null ) return '';
        return `${employee.user_name} (${employee.dept_name} / ${employee.pos_name}) - [${employee.use_annual_count}]`
    }

    getProfileImage(approver) {
        if( approver.user_pic != null ) {
            return `/app_images/${approver.user_pic}`;
        }
        return `/app_images/profile/user/${Math.floor((approver.user_id ? approver.user_id : 0) / 10000)}/${approver.user_id}.jpg`;
    }

    /**
     * 리사이즈 감지
     */
    handleResize() : void {

        // @ts-ignore
        $('#approval_create_scroll').mCustomScrollbar('destroy');

        this.setScroll();
    }

}
