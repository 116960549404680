import { render, staticRenderFns } from "./ApprovalApproverFavoriteModal.vue?vue&type=template&id=78f1b134&scoped=true&"
import script from "./ApprovalApproverFavoriteModal.vue?vue&type=script&lang=ts&"
export * from "./ApprovalApproverFavoriteModal.vue?vue&type=script&lang=ts&"
import style0 from "./ApprovalApproverFavoriteModal.vue?vue&type=style&index=0&id=78f1b134&prod&lang=css&"
import style1 from "./ApprovalApproverFavoriteModal.vue?vue&type=style&index=1&id=78f1b134&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78f1b134",
  null
  
)

export default component.exports